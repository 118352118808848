import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { ReactComponent as PencilIcon } from 'assets/icons/Pencil.svg'

import styles from './SettingsEditButton.module.scss'

export type TSettingsEditButtonProps = {
  onClick?: () => void
}

export const SettingsEditButton = ({ onClick }: TSettingsEditButtonProps) => {
  const { t } = useTranslation()
  const { push } = useHistory()

  const handleClick = () => {
    if (onClick) {
      onClick()
    } else {
      push('/profile/edit/basic')
    }
  }

  return (
    <div onClick={handleClick} className={styles.root}>
      <PencilIcon className={styles.rootIcon} />
      <span>{t('settings.personalInformation.editButton')}</span>
    </div>
  )
}
