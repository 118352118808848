import { AnyObject, Form, FormProps } from 'react-final-form'

import { validation } from '../../../utils'

export type TScheduleMeetingModalFormProps<T extends AnyObject> = FormProps<T>

export const ScheduleMeetingModalForm = <T extends AnyObject>({
  validate,
  ...props
}: TScheduleMeetingModalFormProps<T>) => (
  <Form
    validate={(values) => {
      let errors = values.time
        ? {
            time: validation.composeValidators(
              validation.timeRangeMinDate(values.date),
              validation.timeRange()
            )(values.time, values)
          }
        : {}

      if (validate) {
        errors = { ...errors, ...validate(values) }
      }

      return errors
    }}
    {...props}
  />
)
