import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useQuery } from 'services/query'
import { EFilterTypes, TFilterItem } from 'App/components/common/Filters'
import { getEvent } from 'api/events'

export type TEventNetworkingSelectedFilters = Partial<{
  general: ('online' | 'onlyContacts' | 'openForNetworking')[]
  interests: string[]
}>

const EVENT_QUERY_KEY = 'get-event'

export const useEventNetworkingFilters = (eventId?: string | null) => {
  const { t } = useTranslation()

  const { data = [], isFetching } = useQuery({
    queryKey: [EVENT_QUERY_KEY],
    queryFn: eventId ? () => getEvent(eventId) : undefined,
    enabled: Boolean(eventId),
    select: (response) =>
      response?.interests
        .filter((interest) => interest.enabled)
        .map(({ id, name }) => ({ id, name }))
  })

  const filters = useMemo<TFilterItem<keyof TEventNetworkingSelectedFilters>[]>(
    () => [
      {
        title: t('events.networking.filters.statusGroup'),
        sectionKey: 'general',
        items: [
          {
            filterKey: 'general',
            valueKey: 'key',
            labelKey: 'label',
            type: EFilterTypes.CHECKBOX,
            options: [
              {
                key: 'openForNetworking',
                label: t('events.networking.filters.openForNetworking')
              },
              {
                key: 'online',
                label: t('events.networking.filters.online')
              },
              {
                key: 'onlyContacts',
                label: t('events.networking.filters.contacts')
              }
            ]
          }
        ]
      },
      {
        title: t('events.networking.filters.interestsGroup'),
        sectionKey: 'interests',
        items: [
          {
            filterKey: 'interests',
            valueKey: 'id',
            labelKey: 'name',
            type: EFilterTypes.CHECKBOX,
            options: data,
            loading: isFetching,
            withSelectAll: true
          }
        ]
      }
    ],
    [data, isFetching, t]
  )

  return { filters }
}
