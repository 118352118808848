import { Trans, useTranslation } from 'react-i18next'

import { SettingsInfoRow } from 'App/components'
import { TSettingsBusinessInfoCompanyNameContainerProps } from 'App/containers'

import { SettingsEditButton } from '../../SettingsEditButton'

import styles from './SettingsBusinessInfoCompanyName.module.scss'

export type TSettingsBusinessInfoCompanyNameProps = TSettingsBusinessInfoCompanyNameContainerProps

export const SettingsBusinessInfoCompanyName = ({
  companyName
}: TSettingsBusinessInfoCompanyNameProps) => {
  const { t } = useTranslation()

  return (
    <SettingsInfoRow
      label={
        <div className={styles.name}>
          <Trans t={t} i18nKey="settings.personalInformation.companyNameLabel" />
        </div>
      }
      description={companyName}
      action={<SettingsEditButton />}
    />
  )
}
