import { useEffect, useMemo } from 'react'
import { Field, Form } from 'react-final-form'
import { CompanySizeType } from '@medentee/enums'
import cls from 'classnames'
import { useTranslation } from 'react-i18next'

import { validation } from 'utils/validation'
import {
  TextField,
  Button,
  ErrorModal,
  TextFieldSkeleton,
  FormSubmitButtonSkeleton
} from 'App/components'
import { Select } from 'App/components/common/Fields/Select'
import { TCountry } from 'store/misc'
import {
  COMPANY_SIZE_TYPE,
  COMPANY_SIZE_TYPE_MAP,
  MAX_LENGTH_1000,
  MAX_HEADLINE_LENGTH,
  MAX_LENGTH_100,
  MAX_LENGTH_50
} from 'globalConstants'
import { TBusinessAccountBasicInformationFormProps } from 'App/containers'
import styles from 'App/components/UserProfile/UserProfileBasicInformationPrivateForm/UserProfileBasicInformationPrivateForm.module.scss'

export const BusinessAccountBasicInformationForm = ({
  companyName,
  about,
  headline,
  country,
  city,
  countries,
  cities,
  zip,
  addressLine1,
  addressLine2,
  size,
  loading,
  processing,
  error,
  onSubmit,
  getCities,
  removeErrorNotification,
  onFormChange
}: TBusinessAccountBasicInformationFormProps) => {
  const { t } = useTranslation()

  useEffect(() => {
    country.code && getCities({ countryCode: country.code })
  }, [getCities, country])

  useEffect(
    () => () => {
      removeErrorNotification()
    },
    [removeErrorNotification]
  )

  const initialFormValues = useMemo(
    () => ({
      size: {
        value: size,
        label: COMPANY_SIZE_TYPE_MAP[size]
      },
      companyName,
      about,
      headline,
      country,
      city,
      zip,
      addressLine1,
      addressLine2
    }),
    [about, addressLine1, addressLine2, city, companyName, country, headline, size, zip]
  )

  const onChange = () => onFormChange()

  return (
    <Form onSubmit={onSubmit} initialValues={initialFormValues}>
      {({ handleSubmit, form }) => (
        <form onSubmit={handleSubmit} className={styles.form} onChange={onChange}>
          <div className={cls(styles.formRow, loading && styles.formRowSkeleton)}>
            <TextFieldSkeleton loading={loading}>
              <Field
                name="companyName"
                validate={validation.composeValidators(
                  validation.required(),
                  validation.companyName(),
                  validation.maxLength(MAX_LENGTH_50)
                )}
              >
                {({ input, meta }) => (
                  <TextField
                    {...input}
                    invalid={meta.touched && meta.invalid}
                    error={meta.error}
                    classes={{ wrapper: styles.input }}
                    topLabel={t(
                      'userProfile.businessAccountBasicInformationForm.fields.companyName'
                    )}
                    valueLengthMax={MAX_LENGTH_50}
                  />
                )}
              </Field>
            </TextFieldSkeleton>
            <TextFieldSkeleton loading={loading}>
              <Field name="country" validate={validation.required()}>
                {({ input, meta }) => {
                  const onInputChange = (value: TCountry) => {
                    onFormChange()
                    input.onChange(value)
                    form.change('city', undefined)
                    getCities({ countryCode: value.code })
                  }

                  return (
                    <Select
                      value={input.value}
                      options={countries}
                      labelKey="countryName"
                      valueKey="code"
                      invalid={meta.touched && meta.invalid}
                      error={meta.error}
                      wrapperClassName={styles.input}
                      topLabel={t('userProfile.businessAccountBasicInformationForm.fields.country')}
                      onChange={onInputChange}
                    />
                  )
                }}
              </Field>
            </TextFieldSkeleton>
          </div>
          <div className={cls(styles.formRow, loading && styles.formRowSkeleton)}>
            <TextFieldSkeleton loading={loading}>
              <Field name="city" validate={validation.required()}>
                {({ input, meta }) => {
                  const onInputChange = (value: CompanySizeType) => {
                    onFormChange()
                    input.onChange(value)
                  }

                  return (
                    <Select
                      value={input.value}
                      options={cities}
                      labelKey="cityName"
                      valueKey="id"
                      invalid={meta.touched && meta.invalid}
                      error={meta.error}
                      wrapperClassName={styles.input}
                      topLabel={t('userProfile.businessAccountBasicInformationForm.fields.city')}
                      onChange={onInputChange}
                    />
                  )
                }}
              </Field>
            </TextFieldSkeleton>
            <TextFieldSkeleton loading={loading}>
              <Field name="size" validate={validation.required()}>
                {({ input, meta }) => {
                  const onInputChange = (value: CompanySizeType) => {
                    onFormChange()
                    input.onChange(value)
                  }

                  return (
                    <Select
                      value={input.value}
                      options={COMPANY_SIZE_TYPE}
                      invalid={meta.touched && meta.invalid}
                      error={meta.error}
                      wrapperClassName={styles.input}
                      topLabel={t(
                        'userProfile.businessAccountBasicInformationForm.fields.companySize'
                      )}
                      onChange={onInputChange}
                    />
                  )
                }}
              </Field>
            </TextFieldSkeleton>
            <TextFieldSkeleton loading={loading}>
              <Field
                name="zip"
                validate={validation.composeValidators(
                  validation.required(),
                  validation.isZip(),
                  validation.onlySpaces()
                )}
              >
                {({ input, meta }) => (
                  <TextField
                    {...input}
                    invalid={meta.touched && meta.invalid}
                    error={meta.error}
                    classes={{ wrapper: styles.input }}
                    topLabel={t('userProfile.businessAccountBasicInformationForm.fields.zip')}
                  />
                )}
              </Field>
            </TextFieldSkeleton>
          </div>
          <div className={cls(styles.formRow, loading && styles.formRowSkeleton)}>
            <TextFieldSkeleton loading={loading}>
              <Field
                name="addressLine1"
                validate={validation.composeValidators(
                  validation.required(),
                  validation.onlySpaces(),
                  validation.maxLength(MAX_LENGTH_100)
                )}
              >
                {({ input, meta }) => (
                  <TextField
                    {...input}
                    invalid={meta.touched && meta.invalid}
                    error={meta.error}
                    classes={{ wrapper: styles.input }}
                    topLabel={t('userProfile.businessAccountBasicInformationForm.fields.address1')}
                    valueLengthMax={MAX_LENGTH_100}
                  />
                )}
              </Field>
            </TextFieldSkeleton>
            <TextFieldSkeleton loading={loading}>
              <Field
                name="addressLine2"
                validate={validation.composeValidators(
                  validation.onlySpaces(),
                  validation.maxLength(MAX_LENGTH_100)
                )}
              >
                {({ input, meta }) => (
                  <TextField
                    {...input}
                    invalid={meta.touched && meta.invalid}
                    error={meta.error}
                    classes={{ wrapper: styles.input }}
                    topLabel={t('userProfile.businessAccountBasicInformationForm.fields.address2')}
                    valueLengthMax={MAX_LENGTH_100}
                  />
                )}
              </Field>
            </TextFieldSkeleton>
          </div>
          <div className={cls(styles.formRow, loading && styles.formRowSkeleton)}>
            <TextFieldSkeleton loading={loading} variant="textArea">
              <Field
                name="headline"
                validate={validation.composeValidators(
                  validation.maxLength(MAX_HEADLINE_LENGTH),
                  validation.onlySpaces()
                )}
              >
                {({ input, meta }) => (
                  <TextField
                    {...input}
                    invalid={meta.touched && meta.invalid}
                    error={meta.error}
                    multiline={true}
                    rows={4}
                    rowsMax={4}
                    topLabel={t('userProfile.businessAccountBasicInformationForm.fields.headline')}
                    valueLengthMax={MAX_HEADLINE_LENGTH}
                  />
                )}
              </Field>
            </TextFieldSkeleton>
          </div>
          <div className={cls(styles.formRow, loading && styles.formRowSkeleton)}>
            <TextFieldSkeleton loading={loading} variant="textArea">
              <Field
                name="about"
                validate={validation.composeValidators(
                  validation.onlySpaces(),
                  validation.maxLength(MAX_LENGTH_1000)
                )}
              >
                {({ input, meta }) => (
                  <TextField
                    {...input}
                    invalid={meta.touched && meta.invalid}
                    error={meta.error}
                    multiline={true}
                    rows={4}
                    rowsMax={4}
                    topLabel={t('userProfile.businessAccountBasicInformationForm.fields.about')}
                    valueLengthMax={MAX_LENGTH_1000}
                  />
                )}
              </Field>
            </TextFieldSkeleton>
          </div>

          <FormSubmitButtonSkeleton loading={loading} className={styles.skeletonButton}>
            <Button
              type="submit"
              loading={processing}
              className={styles.button}
              disabled={form.getState().invalid}
            >
              {t('userProfile.businessAccountBasicInformationForm.submitButton')}
            </Button>
          </FormSubmitButtonSkeleton>

          <ErrorModal error={error} />
        </form>
      )}
    </Form>
  )
}
