import { useTranslation } from 'react-i18next'
import copy from 'copy-to-clipboard'

import { toastDefaultOptions } from 'globalConstants'
import { showModalAction, useAppDispatch } from 'store'
import { ReactComponent as ShareIcon } from 'assets/icons/Share.svg'
import { ReactComponent as LinkChainIcon } from 'assets/icons/LinkChain.svg'

import { useToggle } from '../../../../hooks'
import { toast } from '../../../ToastContainer'
import { Button, Popover } from '../../../common'
import { EModalComponents } from '../../../../containers'

import styles from './UnityHubSharePopover.module.scss'

export enum EUnityHubSharePopoverType {
  COMMUNITY = 'COMMUNITY',
  EVENT = 'EVENT'
}

export type TUnityHubSharePopoverProps = {
  id: string
  text: string
  type: EUnityHubSharePopoverType
}

const getShareLink = (id: string, type: EUnityHubSharePopoverType) => {
  const { origin } = window.location

  if (type === EUnityHubSharePopoverType.COMMUNITY) {
    return `${origin}/communities/${id}/p/info`
  }

  return `${origin}/events/${id}/p/info`
}

export const UnityHubSharePopover = ({ id, text, type }: TUnityHubSharePopoverProps) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const { value: open, setValue } = useToggle()

  const shareLink = getShareLink(id, type)

  const handleShareToContacts = () => {
    setValue(false)
    dispatch(
      showModalAction({
        modalTitle: t('modal.broadcastMessage.title', { context: 'share' }),
        modalType: EModalComponents.BROADCAST_MESSAGE_DIALOG,
        modalProps: {
          readonly: true,
          message: `${text} ${shareLink}`,
          recipientsType: 'all'
        }
      })
    )
  }
  const handleCopyLink = () => {
    copy(shareLink)
    setValue(false)
    toast.success(t('common.toast.linkCopied'), toastDefaultOptions)
  }

  return (
    <Popover
      visible={open}
      onVisibleChange={setValue}
      content={
        <div className={styles.popoverContent}>
          <Button
            variant="text"
            icon={<ShareIcon />}
            className={styles.button}
            onClick={handleShareToContacts}
          >
            {t('unityHub.share.toContacts')}
          </Button>
          <Button
            variant="text"
            icon={<LinkChainIcon />}
            className={styles.button}
            onClick={handleCopyLink}
          >
            {t('unityHub.share.copyLink')}
          </Button>
        </div>
      }
    >
      <Button className={styles.root}>{t('unityHub.share.button')}</Button>
    </Popover>
  )
}
