import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'
import { CommunityPublicityEnum } from '@medentee/enums'

import { ReactComponent as LocationOutlineIcon } from 'assets/icons/LocationOutline.svg'
import { TIconLabelProps } from 'App/components/common'
import { QueryBuilder } from 'utils'
import { UnityHubInfo } from 'App/components/UnityHub/UnityHubInfo'
import { UnityHubInfoLocation } from 'App/components/UnityHub/UnityHubInfo/UnityHubInfoLocation'

import { API } from '../../../../services/api'

import { useCommunityInfoRequests } from './useCommunityInfoRequests'

type TCommunityInfoProps = { id?: string }

export const CommunityInfo = ({ id }: TCommunityInfoProps) => {
  const { t } = useTranslation()

  const {
    token,
    isFetching,
    isLoading,
    data,
    communityId,
    shouldShowJoin,
    shouldShowGoToChannels,
    isCommunityMember,
    handleGoToChannels,
    handleJoinCommunity
  } = useCommunityInfoRequests(id)

  const coverFileUrl = new QueryBuilder(API.GET_COMMUNITY_COVER_URL(communityId))
    .custom('invitationToken', token)
    .build()

  const { title = '', organization, country, tags, description } = data ?? {}

  const details = useMemo<TIconLabelProps[]>(
    () => [
      {
        align: 'start',
        icon: <LocationOutlineIcon />,
        label: <UnityHubInfoLocation location={country?.countryName} />
      }
    ],
    [country?.countryName]
  )

  return (
    <UnityHubInfo
      id={communityId}
      about={description}
      isPublic={data?.publicity === CommunityPublicityEnum.PUBLIC}
      coverFileUrl={coverFileUrl}
      aboutTitle={t('communities.info.aboutCommunityLabel')}
      joinButtonLabel={t('communities.info.joinCommunityButton')}
      title={title}
      invitationToken={token}
      organizer={{
        userId: organization?.id ?? '',
        displayUserName: organization?.displayUserName ?? '',
        firstName: organization?.firstName ?? '',
        lastName: organization?.lastName ?? '',
        shouldRedirectToUserProfile: isCommunityMember,
        isCommunity: true,
        label: t('unityHub.info.organizerLabel')
      }}
      details={details}
      tags={tags}
      shouldShowGoToChannels={shouldShowGoToChannels}
      shouldShowJoin={shouldShowJoin}
      isFetching={isFetching || !organization}
      isJoinProcessing={isLoading}
      isCommunity={true}
      onJoin={handleJoinCommunity}
      onGoToChannels={handleGoToChannels}
    />
  )
}
