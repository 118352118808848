import {
  EventPublicityEnum,
  EventRepresentativeTypeEnum,
  ProfileLinkTypeNamesEnum
} from '@medentee/enums'
import { useMemo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { utcToZonedTime } from 'date-fns-tz'

import { TIconLabelProps } from 'App/components/common'
import { QueryBuilder, formatDate, isSameDate } from 'utils'
import { ReactComponent as LocationOutlineIcon } from 'assets/icons/LocationOutline.svg'
import { ReactComponent as ScheduleIcon } from 'assets/icons/Schedule.svg'
import { ReactComponent as WebIcon } from 'assets/icons/Web.svg'
import { API } from 'services/api'
import { UnityHubInfo } from 'App/components/UnityHub/UnityHubInfo'
import { UnityHubInfoLocation } from 'App/components/UnityHub/UnityHubInfo/UnityHubInfoLocation'
import { UnityHubInfoLink } from 'App/components/UnityHub/UnityHubInfo/UnityHubInfoLink'
import { TUnityHubInfoOwnerAccountProps } from 'App/components/UnityHub/UnityHubInfo/UnityHubInfoOwnerAccount'

import { useEventsInfoRequests } from './useEventsInfoRequests'
import styles from './EventsInfo.module.scss'
import { EventsInfoMembers } from './EventsInfoMembers'

type TEventsInfoProps = { id?: string }

export const EventsInfo = ({ id }: TEventsInfoProps) => {
  const { t } = useTranslation()

  const {
    token,
    isFetching,
    isLoading,
    data,
    eventId,
    shouldShowJoin,
    shouldShowGoToChannels,
    handleGoToChannels,
    handleJoinEvent
  } = useEventsInfoRequests(id)

  const {
    settings,
    country,
    city,
    headline,
    about,
    links,
    attendees,
    attendeesCount,
    startOn,
    endOn,
    isEventMember,
    organizerId,
    title = '',
    location = ''
  } = data ?? {}

  const socialLinks = useMemo(
    () => links?.filter((link) => link.type !== ProfileLinkTypeNamesEnum.WEBSITE && link.url),
    [links]
  )

  const regularLinks = useMemo(
    () => links?.filter((link) => link.type === ProfileLinkTypeNamesEnum.WEBSITE),
    [links]
  )

  const dateLabel = useMemo(() => {
    if (!startOn || !endOn) {
      return
    }

    const template = 'dd MMM, yyyy'
    const isOneDay = isSameDate(startOn, endOn)

    if (isOneDay) {
      return formatDate(startOn, template)
    }

    const endOnUTC = utcToZonedTime(new Date(endOn), 'UTC')

    return `${formatDate(startOn, 'dd')} - ${formatDate(endOnUTC, template)}`
  }, [endOn, startOn])

  const details = useMemo<TIconLabelProps[]>(
    () => [
      {
        icon: <ScheduleIcon />,
        label: dateLabel
      },
      {
        align: 'start',
        icon: <LocationOutlineIcon />,
        label: (
          <UnityHubInfoLocation
            cityName={city?.cityName}
            countryName={country?.countryName}
            location={location}
          />
        )
      },
      ...(regularLinks
        ? regularLinks?.map((link) => ({
            key: link.id,
            icon: <WebIcon />,
            label: <UnityHubInfoLink link={link.url} />
          }))
        : [])
    ],
    [city?.cityName, country?.countryName, dateLabel, location, regularLinks]
  )

  const getHeadline = useCallback(
    () => <div className={styles.headline}>{headline}</div>,
    [headline]
  )

  const shouldShowMembers = !!attendees?.length && attendeesCount && id && organizerId

  const getMembers = useCallback(
    () =>
      shouldShowMembers && (
        <EventsInfoMembers
          attendees={attendees}
          id={id}
          isEventMember={isEventMember}
          organizerId={organizerId}
          attendeesCount={attendeesCount}
        />
      ),
    [attendees, attendeesCount, id, isEventMember, organizerId, shouldShowMembers]
  )

  const provider = useMemo<TUnityHubInfoOwnerAccountProps>(
    () => ({
      userId: settings?.representative.id ?? '',
      displayUserName: settings?.representative.displayUserName ?? '',
      firstName: settings?.representative.firstName ?? '',
      lastName: settings?.representative.lastName ?? '',
      isCommunity: settings?.representative.type === EventRepresentativeTypeEnum.COMMUNITY,
      label: t('unityHub.info.ownerTypeLabel', { context: settings?.representative.type }),
      title: t('events.eventInfo.providerTitle')
    }),
    [settings?.representative, t]
  )

  const organizer = useMemo<TUnityHubInfoOwnerAccountProps>(() => {
    if (!settings?.differentOrganizerName) {
      return { ...provider, title: t('events.eventInfo.organizerTitle') }
    }

    return {
      eventId,
      userId: '',
      displayUserName: settings?.differentOrganizerName ?? '',
      firstName: '',
      lastName: '',
      title: t('events.eventInfo.organizerTitle'),
      isEventOrganizer: true
    }
  }, [eventId, provider, settings?.differentOrganizerName, t])

  const coverFileUrl =
    eventId && new QueryBuilder(API.EVENT_COVER(eventId)).custom('invitationToken', token).build()

  return (
    <UnityHubInfo
      id={eventId}
      about={about}
      isPublic={settings?.publicity === EventPublicityEnum.PUBLIC}
      coverFileUrl={coverFileUrl}
      aboutTitle={t('events.eventInfo.about')}
      joinButtonLabel={t('events.eventInfo.joinEventButton')}
      title={title}
      invitationToken={token}
      organizer={organizer}
      provider={settings?.differentOrganizerName ? provider : undefined}
      details={details}
      headline={getHeadline()}
      members={getMembers()}
      socialLinks={socialLinks}
      shouldShowGoToChannels={shouldShowGoToChannels}
      shouldShowJoin={shouldShowJoin}
      isFetching={isFetching || !settings?.representative}
      isJoinProcessing={isLoading}
      event={
        data
          ? {
              dateLabel,
              settings: data?.settings,
              isViewableCommunity: data?.isViewableCommunity
            }
          : undefined
      }
      allowFullscreen={true}
      onJoin={handleJoinEvent}
      onGoToChannels={handleGoToChannels}
    />
  )
}
