import { useCallback, useEffect, useMemo } from 'react'
import { CaseClassificationNameEnum, CaseStatusesEnum } from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import { createSelectorOptions } from 'utils'
import { EAttachLinkedFilesFilterDirections, EInputSize } from 'enums'
import {
  resetAttachFilesAction,
  useAppDispatch,
  useAppSelector,
  createLoadingSelector,
  GET_CASE_LINKED_FILES,
  setSelectedAttachFiles,
  createProcessingSelector,
  ATTACH_CASE_LINKED_FILES,
  attachCaseLinkedFilesRequest,
  removeErrorNotification,
  getCaseLinkedFilesRequest,
  setCaseLinkedFilesSearch,
  resetCaseLinkedFilesFilter,
  setCaseLinkedFilesFilter,
  TLinkedCasesItem,
  accountIdSelector
} from 'store'
import {
  Checkbox,
  EEmptyListIconSize,
  EFilterTypes,
  EmptyList,
  FilteringResultsPlaceholder,
  Search,
  SearchWrapper,
  StepsFooterActions,
  useFilters
} from 'App/components'
import { FileAttachLists } from 'App/components/File/FileAttachDialog/FileAttachLists'
import { ReactComponent as ImageFileIcon } from 'assets/icons/ImageFile.svg'

import { Description } from '../Description'

import styles from './SecondStep.module.scss'

export type TSecondStepProps = {
  caseId: string
  selectedCase: TLinkedCasesItem
  onBack: () => void
}

const loadingSelector = createLoadingSelector([GET_CASE_LINKED_FILES])
const processingSelector = createProcessingSelector([ATTACH_CASE_LINKED_FILES])

export const SecondStep = ({ selectedCase, caseId, onBack }: TSecondStepProps) => {
  const dispatch = useAppDispatch()

  const ids = useAppSelector((state) => state.medCloud.attachFiles.ids)
  const { directions = [], page = 0 } = useAppSelector(
    (state) => state.medCloud.attachFiles.filters
  )
  const total = useAppSelector((state) => state.medCloud.attachFiles.total)
  const search = useAppSelector((state) => state.medCloud.attachFiles.search)
  const selectedFiles = useAppSelector((state) => state.medCloud.attachFiles.selectedFiles)
  const notAttachedIds = useAppSelector((state) =>
    state.medCloud.attachFiles.ids.filter((id) => !state.medCloud.attachFiles.list[id]?.isAttached)
  )
  const loading = useAppSelector(loadingSelector)
  const processing = useAppSelector(processingSelector)
  const accountId = useAppSelector(accountIdSelector)

  const { t } = useTranslation()

  const isMember = selectedCase.owner.id !== accountId
  const isLockedCase = selectedCase.status === CaseStatusesEnum.LOCKED

  const notFoundSearch = Boolean(!ids.length && search.length)
  const notFoundFilter = Boolean(!ids.length && directions.length)

  const shouldShowEmptyListPlaceholder =
    !ids.length && !search.length && !directions.length && !loading
  const shouldShowList = !shouldShowEmptyListPlaceholder && !notFoundFilter && !notFoundSearch
  const shouldShowFilter =
    Boolean(selectedCase.type) &&
    selectedCase.classification.name !== CaseClassificationNameEnum.PRIVATE &&
    !(isMember && isLockedCase)

  const handleSearch = useCallback(
    (value: string) => {
      dispatch(setCaseLinkedFilesSearch({ search: value }))
    },
    [dispatch]
  )

  const setFilter = useCallback<typeof setCaseLinkedFilesFilter>(
    (payload) => dispatch(setCaseLinkedFilesFilter(payload)),
    [dispatch]
  )

  const resetFilter = useCallback(() => {
    dispatch(resetCaseLinkedFilesFilter())
  }, [dispatch])

  const data = useMemo(() => ids.map((id) => ({ id })), [ids])

  const options = useMemo(() => {
    const optionsLabels: Record<EAttachLinkedFilesFilterDirections, string> = isMember
      ? {
          [EAttachLinkedFilesFilterDirections.REVERSE]: t('modal.attachFile.filters.caseFiles'),
          [EAttachLinkedFilesFilterDirections.FORWARD]: t('modal.attachFile.filters.uploaded')
        }
      : {
          [EAttachLinkedFilesFilterDirections.FORWARD]: t('modal.attachFile.filters.caseFiles'),
          [EAttachLinkedFilesFilterDirections.REVERSE]: t('modal.attachFile.filters.shared')
        }

    const optionsValues = Object.values(EAttachLinkedFilesFilterDirections)

    return createSelectorOptions(
      isMember ? [...optionsValues].reverse() : optionsValues,
      (value) => optionsLabels[value]
    )
  }, [isMember, t])

  const { filters } = useFilters({
    innerPopoverProps: { getPopupContainer: (node) => node },
    classes: { popover: styles.filterPopover },
    popover: true,
    collapse: false,
    buttonVariant: 'button',
    value: { directions },
    filters: [
      {
        title: t('modal.attachFile.filters.title'),
        sectionKey: 'directions',
        items: [
          {
            filterKey: 'directions',
            type: EFilterTypes.CHECKBOX,
            options
          }
        ]
      }
    ],
    onReset: resetFilter,
    onChange: (_, value) => setFilter({ directions: value as EAttachLinkedFilesFilterDirections[] })
  })

  const handleSelectAll = useCallback(() => {
    dispatch(
      setSelectedAttachFiles({
        ids: selectedFiles.length === notAttachedIds.length ? [] : notAttachedIds
      })
    )
  }, [dispatch, notAttachedIds, selectedFiles.length])

  const handleSubmit = useCallback(() => {
    dispatch(
      attachCaseLinkedFilesRequest({
        caseId,
        onError: onBack
      })
    )
  }, [dispatch, caseId, onBack])

  const next = useCallback(
    (nextPage = 0) => {
      setFilter({ page: nextPage })
    },
    [setFilter]
  )

  useEffect(() => {
    dispatch(
      getCaseLinkedFilesRequest({
        caseId,
        linkedCaseId: selectedCase.id,
        onError: onBack
      })
    )
    // Filters are required to trigger list update
  }, [caseId, selectedCase.id, dispatch, onBack, search, directions, page])

  useEffect(() => {
    dispatch(removeErrorNotification(GET_CASE_LINKED_FILES))
    dispatch(removeErrorNotification(ATTACH_CASE_LINKED_FILES))

    return () => {
      dispatch(resetAttachFilesAction())
    }
  }, [dispatch])

  return (
    <div className={styles.root}>
      <Description>{selectedCase.title}</Description>

      {!shouldShowEmptyListPlaceholder && (
        <SearchWrapper filters={shouldShowFilter ? filters : undefined} className={styles.search}>
          <Search
            searchSize={EInputSize.L}
            placeholder={t('modal.attachFile.searchPlaceholder')}
            onChange={handleSearch}
          />
        </SearchWrapper>
      )}

      <FilteringResultsPlaceholder
        className={styles.notFound}
        notFoundSearch={notFoundSearch}
        notFoundFilter={notFoundFilter}
      />

      {shouldShowEmptyListPlaceholder && (
        <EmptyList
          icon={<ImageFileIcon className={styles.emptyIcon} />}
          className={styles.empty}
          iconSize={EEmptyListIconSize.SM}
          text={t('modal.attachFile.placeholder_caseFiles')}
        />
      )}

      {shouldShowList && (
        <FileAttachLists
          data={data}
          loading={loading}
          next={next}
          total={total}
          page={page ?? 0}
          title={
            <Checkbox
              label={t('modal.attachFile.selectAllLabel')}
              classes={{ root: styles.checkbox, label: styles.checkboxLabel }}
              checked={!!selectedFiles.length && selectedFiles.length === ids.length}
              indeterminate={!!selectedFiles.length && selectedFiles.length < ids.length}
              onChange={handleSelectAll}
            />
          }
        />
      )}

      <StepsFooterActions
        className={styles.footerActions}
        onClickBack={onBack}
        onSubmit={handleSubmit}
        submitLabel={t('modal.attachFile.submitButton', {
          postProcess: 'interval',
          count: selectedFiles.length
        })}
        submitDisabled={!selectedFiles.length}
        submitLoading={processing}
      />
    </div>
  )
}
