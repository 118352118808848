import { useCallback } from 'react'
import { generatePath, useHistory } from 'react-router-dom'
import { ContactStatusEnum } from '@medentee/enums'

import { EChatTypeMap, CHAT_PATH } from 'globalConstants'
import {
  ButtonInviteToContact,
  TButtonInviteToContactProps
} from 'App/components/ButtonInviteToContact'
import {
  ButtonMessageContainer,
  TButtonMessageContainerProps
} from 'App/containers/ButtonMessageContainer'
import {
  ButtonScheduleMeeting,
  TButtonScheduleMeetingProps
} from 'App/components/ButtonScheduleMeeting'

import styles from './UnityHubNetworkingItemActions.module.scss'

export type TUnityHubNetworkingItemActionsProps = Pick<TButtonInviteToContactProps, 'userId'> &
  Pick<TButtonMessageContainerProps, 'chatId'> &
  Pick<TButtonScheduleMeetingProps, 'userName'> & {
    isContact: boolean
    onContactInviteSuccess: () => void

    contactInviteStatus?: ContactStatusEnum
    disableInvitation?: boolean
  }

export const UnityHubNetworkingItemActions = ({
  chatId,
  userId,
  userName,
  isContact,
  contactInviteStatus,
  onContactInviteSuccess,
  disableInvitation = false
}: TUnityHubNetworkingItemActionsProps) => {
  const { push } = useHistory()

  const handleMessageClick = useCallback(() => {
    push(
      generatePath(CHAT_PATH, {
        chatId,
        chatType: EChatTypeMap.DIALOGUE
      })
    )
  }, [chatId, push])

  const shouldShowContactActions = isContact && chatId

  if (shouldShowContactActions) {
    return (
      <div className={styles.root}>
        <ButtonMessageContainer chatId={chatId} onClick={handleMessageClick} />
        <ButtonScheduleMeeting chatId={chatId} userName={userName} />
      </div>
    )
  }

  return !disableInvitation ? (
    <ButtonInviteToContact
      disabled={contactInviteStatus === ContactStatusEnum.PENDING}
      userId={userId}
      onSuccess={onContactInviteSuccess}
    />
  ) : null
}
