import { Trans, useTranslation } from 'react-i18next'

import { TSettingsPersonalInfoFullNameContainerProps } from 'App/containers'
import { SettingsInfoRow } from 'App/components'

import { SettingsEditButton } from '../../SettingsEditButton'

import styles from './SettingsPersonalInfoFullName.module.scss'

export type TSettingsPersonalInfoFullNameProps = TSettingsPersonalInfoFullNameContainerProps

export const SettingsPersonalInfoFullName = ({ fullName }: TSettingsPersonalInfoFullNameProps) => {
  const { t } = useTranslation()

  return (
    <SettingsInfoRow
      label={
        <div className={styles.name}>
          <Trans t={t} i18nKey="settings.personalInformation.fullNameLabel" />
        </div>
      }
      description={fullName}
      action={<SettingsEditButton />}
    />
  )
}
