import { Field, useFormState } from 'react-final-form'
import { Col } from 'antd'
import { startOfDay } from 'date-fns'
import { useTranslation } from 'react-i18next'

import { validation } from 'utils'
import { TextField, TScheduleMeetingModalFormValues } from 'App/components'
import { MAX_LENGTH_300 } from 'globalConstants'

import { TimeZoneSelect } from '../../common'
import { DatePicker, TimeRangePicker } from '../../common/Fields'

const TITLE_MAX_LENGTH = 120
const MINUTE_STEP = 15

export const ScheduleMeetingMainFields = () => {
  const {
    values: { type }
  } = useFormState<TScheduleMeetingModalFormValues>()

  const { t } = useTranslation()

  return (
    <>
      <Col xs={24}>
        <Field
          name="title"
          validate={validation.composeValidators(
            validation.required(),
            validation.maxLength(TITLE_MAX_LENGTH)
          )}
        >
          {({ input, meta }) => (
            <TextField
              {...input}
              topLabel={t('modal.scheduleMeeting.detailsStep.form.title')}
              error={meta.error}
              placeholder={t('modal.scheduleMeeting.detailsStep.form.titlePlaceholder')}
              valueLengthMax={TITLE_MAX_LENGTH}
              invalid={meta.touched && meta.invalid}
            />
          )}
        </Field>
      </Col>
      <Col xs={24}>
        <Field
          name="date"
          validate={validation.composeValidators(
            validation.required(),
            validation.minDate(startOfDay(new Date()))
          )}
        >
          {({ input, meta }) => (
            <DatePicker
              {...input}
              topLabel={t('modal.scheduleMeeting.detailsStep.form.date')}
              error={meta.error}
              format="EEEE, Do MMMM"
              placeholder={t('modal.scheduleMeeting.detailsStep.form.datePlaceholder')}
              invalid={meta.touched && meta.invalid}
            />
          )}
        </Field>
      </Col>
      <Col xs={24}>
        <Field name="time">
          {({ input, meta }) => (
            <TimeRangePicker
              {...input}
              order={false}
              error={meta.error}
              topLabel={t('modal.scheduleMeeting.detailsStep.form.selectTime')}
              minuteStep={MINUTE_STEP}
              invalid={meta.touched && meta.invalid}
            />
          )}
        </Field>
      </Col>
      <Col xs={24}>
        <Field name="timeZone" validate={validation.required()}>
          {({ input, meta }) => (
            <TimeZoneSelect
              {...input}
              error={meta.error}
              topLabel={t('modal.scheduleMeeting.detailsStep.form.selectTimeZone')}
              invalid={meta.touched && meta.invalid}
            />
          )}
        </Field>
      </Col>
      {type === 'OFFLINE' && (
        <Col xs={24}>
          <Field
            name="location"
            validate={validation.composeValidators(
              validation.required(),
              validation.onlySpaces(),
              validation.maxLength(MAX_LENGTH_300)
            )}
          >
            {({ input, meta }) => (
              <TextField
                {...input}
                topLabel={t('modal.scheduleMeeting.detailsStep.form.location')}
                error={meta.error}
                valueLengthMax={MAX_LENGTH_300}
                invalid={meta.touched && meta.invalid}
              />
            )}
          </Field>
        </Col>
      )}
    </>
  )
}
