import { call, delay, put, takeEvery, takeLatest, select } from 'redux-saga/effects'
import uniqBy from 'lodash/uniqBy'
import uniq from 'lodash/uniq'
import sum from 'lodash/sum'
import { FileHistorySourcesEnum, ErrorCodesEnum } from '@medentee/enums'

import { API, api, APIData, APIResultsResponse } from 'services/api'
import { FILE_HISTORY_DEFAULT_SHOW_BY } from 'globalConstants'
import { CloudFilesQueryBuilder, downloadFileFromUrl, isMatchErrorCode, QueryBuilder } from 'utils'
import { IFileContactPermissionObject, IFilesEntity } from 'interfaces'
import { State } from 'redux/rootReducer'
import {
  GET_FILES_HISTORY_REQUEST,
  getFilesHistoryError,
  getFilesHistorySuccess,
  hideModalAction,
  TFilesHistory,
  TGetFilesHistoryRequest,
  TGetFilesHistoryResponse,
  videoStreamingNormalize,
  TIds
} from 'store'
import { setVideoStreamingData } from 'store/videoStreaming'
import { Pagination, SortingTable } from 'types'
import { handleError, handleWarning } from 'api/utils'

import {
  CHANGE_FILE_CONTACT_PERMISSION_REQUEST,
  changeFileContactPermissionSuccess,
  DOWNLOAD_FILE_REQUEST,
  GET_FILE_CONTACT_LIST_REQUEST,
  GET_FILE_LIST_REQUEST,
  getFileContactListError,
  getFileContactListSuccess,
  getFileListRequest,
  RENAME_FILE_REQUEST,
  renameFileError,
  renameFileSuccess,
  setFileContactList,
  setFileIds,
  setFileList,
  setFilePagination,
  setFileFiltersRequest,
  setFileSorting,
  setFileDetailsId,
  discardCasesPermissionsError,
  discardCasesPermissionsSuccess,
  DISCARD_CASES_PERMISSIONS_REQUEST,
  setFileFiltersSuccess,
  SET_FILE_LIST_FILTERS_REQUEST,
  changeFileContactPermissionError,
  getFileContactListRequest,
  SET_FILE_CONTACT_LIST_SEARCH,
  GET_FILES_COUNTS_REQUEST,
  getFilesCountsSuccess,
  getFilesCountsError,
  getFileListSuccess,
  getFileListError,
  downloadFileError,
  downloadFileSuccess
} from './files.actions'
import {
  fileContactNormalize,
  fileHistoryNormalize,
  fileNormalize,
  TFileNormalized
} from './files.normalization'
import { FILE_LIST_DEFAULT_PAGINATION } from './files.reducer'
import {
  TSetFileFiltersRequest,
  TGetFileListRequest,
  TChangeFileContactPermissionRequest,
  TGetFileContactListRequest,
  TDownloadFileRequest,
  TRenameFileRequest,
  TDiscardCasesPermissionsRequest,
  TFileContactList,
  TFileContact,
  TSetFileContactListSearch,
  TGetFilesCountsRequest,
  TFilesCounts,
  TFileList,
  TFilesFilters
} from './files.types'

function* getFileListSaga({ payload }: TGetFileListRequest) {
  try {
    const currentFilters: TFilesFilters = yield select((state: State) => state.file.filters)
    const currentPagination: Pagination = yield select((state: State) => state.file.pagination)
    const currentSorting: SortingTable = yield select((state: State) => state.fileShared.sorting)
    const currentIds: TIds = yield select((state: State) => state.file.ids)
    const currentFileList: TFileList = yield select((state: State) => state.file.fileList)

    const {
      ownerId,
      permissionSpecificationScope,
      permissionUserIdScope,
      chatId,
      extensionCategories,
      loadMore,
      pagination = payload.filters
        ? FILE_LIST_DEFAULT_PAGINATION
        : currentPagination || FILE_LIST_DEFAULT_PAGINATION
    } = payload
    const filters = {
      ...currentFilters,
      ...payload.filters
    }
    const sorting = {
      ...currentSorting,
      ...payload.sorting
    }

    const queryBuilderUrl = new CloudFilesQueryBuilder(API.CLOUD_FILES)
      .searchQuery(filters.search)
      .page(pagination.current)
      .sortFiles(sorting?.direction, sorting?.name)
      .showBy(pagination.showBy)
      .permissionSpecificationScope(permissionSpecificationScope)
      .permissionUserIdScope(permissionUserIdScope)
      .extensionCategories(extensionCategories ?? filters.extensionCategories)
      .fileOwnerId(ownerId)
      .chatId(chatId)
      .build()

    const {
      data: { total, results }
    }: APIResultsResponse<IFilesEntity[]> = yield call(api.get, queryBuilderUrl)

    const { fileList, ids } = fileNormalize(results)

    const normalizedData: TFileNormalized =
      loadMore && ids.length
        ? {
            fileList: {
              ...currentFileList,
              ...fileList
            },
            ids: uniq([...currentIds, ...ids])
          }
        : { fileList, ids }

    yield put(setVideoStreamingData(videoStreamingNormalize(results)))
    yield put(setFileList({ fileList: normalizedData.fileList }))
    yield put(setFileIds({ ids: normalizedData.ids }))
    yield put(setFileFiltersRequest({ ...filters, total }))
    yield put(setFilePagination({ pagination }))
    yield put(setFileSorting({ sorting }))
    yield put(getFileListSuccess())
  } catch (e) {
    handleError(e)
    yield put(getFileListError())
  }
}

function* getFileContactPermissions({ payload: { fileId } }: TGetFileContactListRequest) {
  try {
    const search: string = yield select((state: State) => state.file.fileContacts.search)

    const url = new QueryBuilder(API.FILE_CONTACT_PERMISSIONS(fileId)).searchQuery(search).build()

    const { data }: APIData<TFileContact[]> = yield call(api.get, url)

    const { fileContactIds, fileContactList } = fileContactNormalize(data)

    const list: TFileContactList = {}

    fileContactIds.forEach((id: string) => {
      const { p2pPermissions, ...rest } = fileContactList[id]

      list[id] = {
        ...rest,
        p2pPermissions,
        permissions: p2pPermissions
      }
    })

    yield put(setFileContactList({ fileContactList: list, fileContactIds }))
    yield put(getFileContactListSuccess())
  } catch (e) {
    yield put(getFileContactListError())

    if (isMatchErrorCode(e, ErrorCodesEnum.FILE_NOT_FOUND)) {
      const { filters, sorting } = yield select((state: State) => state.file)
      const accountId: string | undefined = yield select(
        (state: State) => state.global.accountData?.id
      )

      yield put(
        setFileDetailsId({
          recordId: null
        })
      )
      yield put(
        getFileListRequest({
          filters,
          sorting,
          ownerId: accountId
        })
      )

      handleWarning(e)
      return
    }

    handleError(e)
  }
}

function* changeFileContactPermissions({
  payload: { fileId, userId, enabled, permissionId }
}: TChangeFileContactPermissionRequest) {
  try {
    const { data }: APIData<IFileContactPermissionObject[]> = yield call(
      api.patch,
      `${API.FILE_CONTACT_CHANGE_PERMISSION(fileId)}`,
      {
        permissionId,
        userId,
        enabled
      }
    )
    yield delay(300)

    const permissions = (data || []).map((permission) => permission.id)

    yield put(
      changeFileContactPermissionSuccess({
        processingId: userId,
        userId,
        permissions
      })
    )
  } catch (e) {
    yield isMatchErrorCode(e, ErrorCodesEnum.FILE_NOT_FOUND) ? handleWarning(e) : handleError(e)

    yield put(changeFileContactPermissionError({ ...e, processingId: userId }))
  }
}

function* downloadFileSaga({ payload }: TDownloadFileRequest) {
  try {
    const { fileId } = payload

    const checkDownloadUrl = new QueryBuilder(API.FILE_DOWNLOAD_URL(fileId))
      .custom('checkRightsOnly', 'true')
      .build()
    const downloadUrl = new QueryBuilder(API.FILE_DOWNLOAD_URL(fileId))
      .custom('source', FileHistorySourcesEnum.MED_CLOUD)
      .build()

    yield call(api.get, checkDownloadUrl)

    const { data }: APIData<string> = yield call(api.get, downloadUrl)

    yield downloadFileFromUrl({ content: data })
    yield put(downloadFileSuccess())
  } catch (e) {
    const accountId: string | undefined = yield select(
      (state: State) => state.global.accountData?.id
    )

    yield put(downloadFileError(e))
    yield put(getFileListRequest({ ownerId: accountId }))

    yield isMatchErrorCode(e, [
      ErrorCodesEnum.FILE_NOT_FOUND,
      ErrorCodesEnum.FILE_NOT_ENOUGH_ACCESS_RIGHTS
    ])
      ? handleWarning(e)
      : handleError(e)
  }
}

function* renameFileSaga({ payload }: TRenameFileRequest) {
  try {
    const { fileId, fileName, onSuccess } = payload

    yield call(api.patch, API.FILE_EDIT(fileId), {
      source: FileHistorySourcesEnum.MED_CLOUD,
      fileName
    })

    if (onSuccess) {
      yield call(onSuccess, fileName)
    }

    yield put(renameFileSuccess())
    yield put(hideModalAction())
  } catch (e) {
    yield put(renameFileError(e))
  }
}

function* discardCasesPermissionsSaga({ payload }: TDiscardCasesPermissionsRequest) {
  const { fileId, contactUserId } = payload

  try {
    yield call(api.delete, API.FILE_DISCARD_PERMISSIONS_CASES(fileId), {
      data: {
        accountId: contactUserId
      }
    })

    yield put(hideModalAction())
    yield put(
      discardCasesPermissionsSuccess({
        contactId: contactUserId
      })
    )
  } catch (e) {
    yield put(discardCasesPermissionsError(e))
  }
}

function* setFileFiltersSaga({ payload }: TSetFileFiltersRequest) {
  try {
    yield put(setFileFiltersSuccess({ ...payload }))
  } catch (e) {
    handleError(e)
  }
}

function* getFilesHistorySaga({ payload }: TGetFilesHistoryRequest) {
  try {
    const {
      page,
      actorAccountIds,
      fileIds,
      sources,
      showBy = FILE_HISTORY_DEFAULT_SHOW_BY,
      originalEntityIds
    } = payload
    const { historyItems: currentHistoryItems }: TFilesHistory = yield select(
      (state: State) => state.file.fileHistory
    )

    const url = new QueryBuilder(API.FILES_HISTORY)
      .page(page)
      .showBy(showBy)
      .multiSearch('actorAccountIds', actorAccountIds)
      .multiSearch('fileIds', fileIds)
      .multiSearch('sources', sources)
      .multiSearch('sources', sources)
      .multiSearch('originalEntityIds', originalEntityIds)
      .build()

    const { data }: APIData<TGetFilesHistoryResponse> = yield call(api.get, url)
    const historyItems = uniqBy([...currentHistoryItems, ...data.results], 'id')

    const normalizedData = fileHistoryNormalize(historyItems)

    yield put(
      getFilesHistorySuccess({
        ...normalizedData,
        total: data.total,
        historyItems,
        page
      })
    )
  } catch (e) {
    yield put(getFilesHistoryError(e))
  }
}

function* refreshFileContactPermissions({ payload: { fileId } }: TSetFileContactListSearch) {
  if (fileId) {
    yield put(getFileContactListRequest({ fileId }))
  }
}

function* getFilesCountsSaga({
  payload: {
    searchQuery,
    caseId,
    ownerId,
    permissionSpecificationScope,
    permissionUserIdScope,
    chatId
  }
}: TGetFilesCountsRequest) {
  try {
    const url = new QueryBuilder(API.CLOUD_COUNTS)
      .searchQuery(searchQuery)
      .custom('ownerId', ownerId)
      .custom('caseId', caseId)
      .custom('chatId', chatId)
      .custom('permissionSpecificationScope[]', permissionSpecificationScope)
      .custom('permissionUserIdScope', permissionUserIdScope)
      .build()

    const { data }: APIData<TFilesCounts> = yield call(api.get, url)

    const payload = {
      counts: data,
      total: sum(Object.values(data))
    }

    yield put(getFilesCountsSuccess(payload))
  } catch (e) {
    yield put(getFilesCountsError(e))
  }
}

export function* fileListSaga() {
  yield takeLatest(GET_FILE_LIST_REQUEST, getFileListSaga)
  yield takeEvery(CHANGE_FILE_CONTACT_PERMISSION_REQUEST, changeFileContactPermissions)
  yield takeLatest(SET_FILE_CONTACT_LIST_SEARCH, refreshFileContactPermissions)
  yield takeLatest(GET_FILE_CONTACT_LIST_REQUEST, getFileContactPermissions)
  yield takeLatest(DOWNLOAD_FILE_REQUEST, downloadFileSaga)
  yield takeLatest(RENAME_FILE_REQUEST, renameFileSaga)
  yield takeLatest(DISCARD_CASES_PERMISSIONS_REQUEST, discardCasesPermissionsSaga)
  yield takeLatest(SET_FILE_LIST_FILTERS_REQUEST, setFileFiltersSaga)
  yield takeLatest(GET_FILES_HISTORY_REQUEST, getFilesHistorySaga)
  yield takeLatest(GET_FILES_COUNTS_REQUEST, getFilesCountsSaga)
}
