import { useEffect, useState } from 'react'
import axios from 'axios'
import { FileHistoryEventsEnum } from '@medentee/enums'

import { displayError, QueryBuilder } from 'utils'
import { api, API } from 'services/api'
import { useProgress } from 'App/hooks'

export type TUseDownloadFileProps = {
  id: string

  source?: string
  originalEntityId?: string
  type?: string
  denyDownload?: boolean
  fileSrc?: string
}

const PROGRESS_START_POINT = 0
const PROGRESS_END_POINT = 100

export const useDownloadFile = ({
  source,
  id,
  originalEntityId,
  type,
  fileSrc,
  denyDownload = false
}: TUseDownloadFileProps) => {
  const [src, setSrc] = useState<string | undefined>()
  const [fileUrl, setFileUrl] = useState<string>('')
  const [fileText, setFileText] = useState<string>('')
  const [error, setError] = useState<string | null>(null)

  const { progress, setProgress, onProgress } = useProgress()

  useEffect(() => {
    const cancelToken = axios.CancelToken
    const { token, cancel } = cancelToken.source()

    setProgress(PROGRESS_START_POINT)
    setError(null)

    if (fileSrc) {
      setSrc(fileSrc)
      setProgress(PROGRESS_END_POINT)

      return
    }

    const url = new QueryBuilder(API.FILE_DOWNLOAD_URL(id))
      .custom('source', source)
      .custom('event', FileHistoryEventsEnum.OPENED)
      .custom('originalEntityId', originalEntityId)
      .build()

    api
      .get<string>(url, {
        responseType: 'text',
        cancelToken: token
      })
      .then(({ data }) => {
        setFileUrl(data)

        if (denyDownload) {
          setProgress(PROGRESS_END_POINT)

          return
        }

        return api
          .get<Blob>(data, {
            responseType: 'blob',
            cancelToken: token,
            withCredentials: false,
            onDownloadProgress: onProgress
          })
          .then((response) => {
            const objectData = new Blob([response.data], type ? { type } : undefined)
            const fileObjectUrl = URL.createObjectURL(objectData)

            setSrc(fileObjectUrl)

            if (response.data.type === 'text/plain') {
              return response.data.text()
            }

            return
          })
          .then((text = '') => setFileText(text))
      })
      .catch((e) => {
        setError(e)
        displayError(e)
      })

    return () => {
      cancel()
    }
  }, [id, originalEntityId, setProgress, onProgress, source, type, denyDownload, fileSrc])

  return {
    fileUrl,
    fileText,
    src,
    error,
    progress
  }
}
