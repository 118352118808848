import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { EModalComponents, TSettingsBusinessInfoEmailContainerProps } from 'App/containers'
import { SettingsInfoRow } from 'App/components'

import { SettingsEditButton } from '../../SettingsEditButton'

export type TSettingsBusinessInfoEmailProps = TSettingsBusinessInfoEmailContainerProps

export const SettingsBusinessInfoEmail = ({
  email,
  showModal
}: TSettingsBusinessInfoEmailProps) => {
  const { t } = useTranslation()

  const handleEmailEdit = useCallback(() => {
    showModal({
      modalTitle: t('modal.changeEmail.title'),
      modalType: EModalComponents.CHANGE_PERSONAL_EMAIL
    })
  }, [showModal, t])

  return (
    <SettingsInfoRow
      label={t('settings.personalInformation.emailLabel')}
      description={email}
      action={<SettingsEditButton onClick={handleEmailEdit} />}
    />
  )
}
