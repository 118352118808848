import { useHistory } from 'react-router'
import cls from 'classnames'
import { useTranslation } from 'react-i18next'

import { IEventDTO } from 'interfaces'
import { EAvatarSize } from 'enums'
import { Avatar, Divider } from 'App/components/common'
import { accountIdSelector, useAppSelector } from 'store'
import { useAdaptiveLayout } from 'App/hooks'

import styles from './EventsInfoMembers.module.scss'

export type TEventsInfoMembersProps = PartialBy<
  Pick<IEventDTO, 'attendees' | 'isEventMember' | 'organizerId' | 'id' | 'attendeesCount'>,
  'isEventMember'
>

export const EventsInfoMembers = ({
  attendees,
  isEventMember,
  organizerId,
  id: eventId,
  attendeesCount
}: TEventsInfoMembersProps) => {
  const accountId = useAppSelector(accountIdSelector)

  const { push } = useHistory()

  const { t } = useTranslation()

  const { isMobile } = useAdaptiveLayout()

  const accessible = isEventMember || organizerId === accountId

  const handleClick = () => {
    if (accessible) {
      push(`/events/${eventId}/highlights/networking`)
    }
  }

  return (
    <div className={cls(styles.root, { [styles.accessible]: accessible })} onClick={handleClick}>
      <div className={styles.header}>
        <div className={styles.title}>
          {t('events.eventInfo.attendeesTitle', {
            count: !isMobile ? attendeesCount : undefined,
            postProcess: 'interval'
          })}
        </div>

        {isMobile && (
          <div className={styles.subtitle}>
            {t('events.eventInfo.attendeesSubtitle', { count: attendeesCount })}
          </div>
        )}
      </div>

      {isMobile && <Divider variant="horizontal" />}

      <div className={styles.avatars}>
        {attendees.map(({ account: { id, displayUserName, lastName, firstName, type } }) => (
          <Avatar
            key={id}
            userId={id}
            size={EAvatarSize.XXS}
            displayUserName={displayUserName}
            firstName={firstName}
            lastName={lastName}
            type={type.name}
            hasTooltip={false}
            border={false}
            online={false}
          />
        ))}
      </div>
    </div>
  )
}
