import { ReactNode, useCallback, useEffect, useMemo } from 'react'
import { FixedSizeList } from 'react-window'
import cls from 'classnames'
import { Skeleton } from 'antd'
import InfiniteLoader from 'react-window-infinite-loader'
import { useTranslation } from 'react-i18next'

import { getMoreItemsLoader, getVirtualListHeight } from 'utils'
import { Search } from 'App/components'
import { useRefValue } from 'App/hooks'
import { EInputSize } from 'enums'
import { useAccumulate } from 'App/hooks/useAccumulate'
import { PAGINATION_DEFAULT_SHOW_BY } from 'types'

import { EmptyList } from '../../EmptyList'
import { TAccountsSelectOptionData } from '../AccountsSelectOption'
import { AccountsSelectListRow, TRowData } from '../AccountsSelectListRow'

import styles from './AccountsSelectOptionsList.module.scss'

// ------------------ Types ------------------
export type TAccountsSelectOptionsListProps = {
  emptyContent?: string
  defaultListEmptyText?: string
  items: TAccountsSelectOptionData[]
  selectedItems: TAccountsSelectOptionData[]

  page?: number
  search?: string
  searchBy?: string
  getItems?: (page: number) => void
  onSelect: (data: TAccountsSelectOptionData) => void
  onUnselect: (id: string) => void
  CustomSearchComponent?: ReactNode
  disabled?: boolean
  total?: number
  onPageChange?: (page: number) => void
  onSearch?: (value: string) => void
  loading?: boolean
  hideSearch?: boolean
  autoOnline?: boolean
  onSelectAll?: (items: TAccountsSelectOptionData[]) => void
  searchPlaceholder?: string
  limit?: number
}
// ------------------  END  ------------------

enum EListConfig {
  HEIGHT = 180,
  ITEM_HEIGHT = 30 + 15 // height + margin-bottom,
}

export const AccountsSelectOptionsList = ({
  getItems,
  onSelect,
  onSearch,
  onUnselect,
  onPageChange,
  CustomSearchComponent,
  disabled = false,
  items,
  page,
  search,
  loading = false,
  total = items.length,
  hideSearch = false,
  autoOnline = false,
  searchPlaceholder,
  limit = PAGINATION_DEFAULT_SHOW_BY,
  selectedItems,
  searchBy,
  ...props
}: TAccountsSelectOptionsListProps) => {
  const { getValue: getPage } = useRefValue(page)
  const { getValue: getOnPageChange } = useRefValue(onPageChange)

  const { t } = useTranslation()

  const emptyContent = props.emptyContent ?? t('modal.accountsSelect.accountsList.placeholder')

  const accumulatedItems = useAccumulate({
    items,
    total,
    page,
    args: { search, searchBy }
  })

  const selectedItemsSet = useMemo(
    () => new Set(selectedItems.map(({ id }) => id)),
    [selectedItems]
  )

  const handlePageChange = useCallback(
    (nextPage: number) => {
      const callback = getOnPageChange()

      callback && callback(nextPage)
    },
    [getOnPageChange]
  )

  useEffect(() => {
    const currentPage = getPage()

    if (getItems && currentPage !== undefined) {
      getItems(currentPage)
    }
  }, [getItems, getPage])

  const itemData: TRowData = {
    results: accumulatedItems,
    selectedItemsSet,
    onSelect,
    onUnselect,
    disabled,
    autoOnline
  }

  const listHeight = getVirtualListHeight({
    arr: accumulatedItems,
    itemHeight: EListConfig.ITEM_HEIGHT,
    defaultHeight: EListConfig.HEIGHT,
    min: 0,
    max: 4
  })

  const loadMoreItems = useMemo(
    () =>
      getMoreItemsLoader({
        limit,
        onLoadNext: handlePageChange
      }),
    [handlePageChange, limit]
  )

  const isItemLoaded = (index: number) => !!accumulatedItems[index]

  return (
    <div className={cls({ [styles.root]: true, [styles.rootWithSkeleton]: loading })}>
      {!hideSearch && (
        <div className={styles.search}>
          {CustomSearchComponent ?? (
            <Search onChange={onSearch} searchSize={EInputSize.L} placeholder={searchPlaceholder} />
          )}
        </div>
      )}

      <Skeleton
        active={true}
        loading={loading}
        title={false}
        paragraph={{
          rows: 3,
          width: '100%'
        }}
      >
        {!total ? (
          <EmptyList text={emptyContent} hasAppliedSearch={!!search} />
        ) : (
          <InfiniteLoader
            isItemLoaded={isItemLoaded}
            itemCount={total}
            loadMoreItems={loadMoreItems}
            threshold={limit / 2}
          >
            {({ onItemsRendered, ref }) => (
              <FixedSizeList
                height={listHeight}
                itemCount={accumulatedItems.length}
                itemSize={EListConfig.ITEM_HEIGHT}
                width="auto"
                itemData={itemData}
                ref={ref}
                onItemsRendered={onItemsRendered}
              >
                {AccountsSelectListRow}
              </FixedSizeList>
            )}
          </InfiniteLoader>
        )}
      </Skeleton>
    </div>
  )
}
