import { useCallback } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { MessageTypeEnum } from '@medentee/enums'

import { setChatHistoryEventAction } from 'store'
import { ChatMessageReference, TChatMessageReferenceProps } from 'App/components'
import { EChatHistoryEvents } from 'enums'

export type TChatMessageReferenceContainerBaseProps = ReturnType<typeof mapDispatchToProps>

export type TChatMessageReferenceContainerProps = TChatMessageReferenceContainerBaseProps &
  Omit<TChatMessageReferenceProps, 'onClick'>

const ChatMessageReferenceContainerView = ({
  message,
  title,
  icon,
  classes,
  outgoing,
  onCancel,
  setEvent
}: TChatMessageReferenceContainerProps) => {
  const handleOnClick = useCallback(() => {
    if (message.type === MessageTypeEnum.DELETED) {
      return
    }

    setEvent({
      type: EChatHistoryEvents.JUMP,
      payload: { messageId: message.id, eventTs: new Date().getTime() }
    })
  }, [message.id, message.type, setEvent])

  return (
    <ChatMessageReference
      message={message}
      title={title}
      icon={icon}
      classes={classes}
      outgoing={outgoing}
      onCancel={onCancel}
      onClick={handleOnClick}
    />
  )
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setEvent: setChatHistoryEventAction
    },
    dispatch
  )

export const ChatMessageReferenceContainer = connect(
  undefined,
  mapDispatchToProps
)(ChatMessageReferenceContainerView)
