import { useState } from 'react'
import noop from 'lodash/noop'

import { useQuery, QueryFunction } from 'services/query'
import { PAGINATION_DEFAULT } from 'types'
import { TGetEventAttendeesPayload, getEventAttendees } from 'api/events'
import { TEventAttendee } from 'interfaces/api/events'
import { TAccountsSelectOptionData } from 'App/components/common/AccountsSelect/AccountsSelectOption'

type TUseEventCreateChannelMembersStepRequest = Pick<TGetEventAttendeesPayload, 'markChatIds'> & {
  eventId: string
}

export const useEventCreateChannelMembersStepRequest = ({
  eventId,
  markChatIds
}: TUseEventCreateChannelMembersStepRequest) => {
  const [search, setSearch] = useState('')
  const [page, setPage] = useState<number>(0)
  const [total, setTotal] = useState(0)

  const queryFn: QueryFunction<TEventAttendee[]> = async () => {
    const data = await getEventAttendees({
      page,
      search,
      eventId,
      showBy: PAGINATION_DEFAULT.showBy,
      markChatIds
    })

    setTotal(data.total)

    return data.results
  }

  const {
    data: result,
    isInitialLoading,
    error
  } = useQuery({
    queryKey: ['text-channel-select-members', page, search],
    queryFn,
    cacheTime: 0,
    keepPreviousData: true,
    enabled: !!eventId,
    onError: noop,
    select: (data) =>
      data.map<TAccountsSelectOptionData>(
        ({ account: { firstName, lastName, displayUserName, id, type } }) => ({
          id,
          firstName,
          lastName,
          type,
          displayUserName
        })
      )
  })

  return {
    showBy: PAGINATION_DEFAULT.showBy,
    results: result ?? [],
    total,
    loading: isInitialLoading,
    search,
    page,
    error,
    handleSearch: setSearch,
    setPage
  }
}
