import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'

import { useQuery } from 'services/query'
import {
  accountIdSelector,
  isAdminAccountSelector,
  isBusinessAccountSelector,
  useAppSelector
} from 'store'
import { EmptyList, Pagination } from 'App/components/common'
import { ReactComponent as AccountGroupIcon } from 'assets/icons/AccountGroup.svg'
import { generateRandomArray } from 'utils/array'
import {
  TGetEventAttendeesPayload,
  getEvent,
  getEventAttendees,
  getPersonalEventProfile
} from 'api/events'
import { PageWrapperContext } from 'App/components/PageWrapper/PageWrapperContext'
import { UnityHubNetworkingItemSkeleton } from 'App/components/UnityHub/UnityHubNetworkingItem/UnityHubNetworkingItemSkeleton'
import { APIResultsResponse } from 'services/api'
import { TEventAttendee } from 'interfaces/api/events'

import { TEventNetworkingSelectedFilters } from '../useEventNetworkingFilters'
import { EventNetworkingListItem } from '../EventNetworkingListItem'

import styles from './EventNetworkingList.module.scss'

const FAKE_ITEMS = generateRandomArray(5)
const EVENT_PERSONAL_PROFILE_QUERY_KEY = 'event_my_profile'
const EVENT_INFO_QUERY_KEY = 'event_info'
export const EVENT_ATTENDEES_LIST_QUERY_KEY = 'event_attendees'

type TEventNetworkingListProps = Pick<TGetEventAttendeesPayload, 'isFavorite'>

export const EventNetworkingList = (props: TEventNetworkingListProps) => {
  const [data, setData] = useState<APIResultsResponse<TEventAttendee[]>['data'] | undefined>()

  const eventId = useAppSelector((store) => store.events.currentEventId)
  const accountId = useAppSelector(accountIdSelector)
  const isBusinessAccount = useAppSelector(isBusinessAccountSelector)
  const isAdmin = useAppSelector(isAdminAccountSelector)

  const { t } = useTranslation()

  const { push } = useHistory()

  const { selectedFilters, hasAppliedFilter, search, searchBy, page, setPage } =
    useContext(PageWrapperContext)

  const { general, interests } = (selectedFilters as TEventNetworkingSelectedFilters) ?? {}

  const { data: eventInfo, isLoading: isFetchingEventInfo } = useQuery({
    queryKey: [EVENT_INFO_QUERY_KEY, eventId],
    queryFn: eventId ? () => getEvent(eventId) : undefined,
    enabled: !!eventId
  })

  const shouldFetchProfile = !!eventId && !isBusinessAccount && eventInfo?.isEventMember
  const isEventOrganizer = accountId === eventInfo?.organizer.id
  const shouldFetchAttendees = !!eventId && (eventInfo?.isEventMember || isEventOrganizer)

  const { isFetching, refetch } = useQuery({
    queryKey: [EVENT_ATTENDEES_LIST_QUERY_KEY, eventId, page, search, selectedFilters],
    queryFn: eventId
      ? () =>
          getEventAttendees({
            page,
            search,
            searchBy,
            eventId,
            isFavorite: props.isFavorite,
            isOnline: Boolean(general && general.includes('online')),
            isContact: Boolean(general && general.includes('onlyContacts')),
            isOpenForNetworking: Boolean(general && general.includes('openForNetworking')),
            interestIds: interests
          })
      : undefined,
    enabled: shouldFetchAttendees && !!eventId,
    onSuccess: setData
  })

  useEffect(() => {
    if (eventInfo && !eventInfo?.isEventMember && eventInfo.organizerId !== accountId) {
      push(`/events/${eventId}`)
    }
  }, [accountId, eventId, eventInfo, push])

  const { data: personalProfile, isFetching: isFetchingPersonalEventProfile } = useQuery({
    queryKey: [EVENT_PERSONAL_PROFILE_QUERY_KEY, eventId],
    queryFn: !!eventId ? () => getPersonalEventProfile(eventId) : undefined,
    enabled: !!shouldFetchProfile && !!eventId
  })

  const handleToggleFavorite = useCallback(
    (attendeeId: string) =>
      props.isFavorite
        ? refetch()
        : setData((prev) => ({
            page: prev?.page ?? 0,
            total: prev?.total ?? 0,
            results:
              prev?.results.map((item) =>
                item.id === attendeeId ? { ...item, isFavorite: !item.isFavorite } : item
              ) ?? []
          })),
    [props.isFavorite, refetch]
  )

  const loading = isFetching || isFetchingPersonalEventProfile || isFetchingEventInfo

  const content = useMemo(() => {
    if (loading) {
      return (
        <div className={styles.content}>
          {FAKE_ITEMS.map((_, index) => (
            <UnityHubNetworkingItemSkeleton
              key={index}
              loading={loading}
              className={styles.skeleton}
            />
          ))}
        </div>
      )
    }

    if (!data?.results.length) {
      return (
        <EmptyList
          text={t('events.networking.placeholder')}
          hasAppliedFilters={hasAppliedFilter}
          hasAppliedSearch={!!search}
          icon={<AccountGroupIcon />}
          className={styles.placeholder}
        />
      )
    }

    return (
      <div className={styles.content}>
        {eventId &&
          data?.results.map(
            ({
              account,
              contact,
              chatId,
              company,
              openForNetworking,
              position,
              online,
              id,
              isFavorite
            }) => (
              <EventNetworkingListItem
                key={account.id}
                id={id}
                eventId={eventId}
                account={account}
                contact={contact}
                chatId={chatId}
                currentAccountId={accountId}
                company={company}
                position={position}
                online={online}
                openForNetworking={openForNetworking}
                isOrganizer={eventInfo?.organizerId === accountId}
                isFavorite={isFavorite}
                isAdmin={isAdmin}
                personalProfile={personalProfile}
                onToggleFavorite={handleToggleFavorite}
              />
            )
          )}
      </div>
    )
  }, [
    loading,
    data?.results,
    eventId,
    t,
    hasAppliedFilter,
    search,
    accountId,
    eventInfo?.organizerId,
    isAdmin,
    personalProfile,
    handleToggleFavorite
  ])

  return (
    <div className={styles.root}>
      {content}

      <Pagination current={page} total={data?.total} onChange={setPage} />
    </div>
  )
}
