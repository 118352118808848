import { useEffect, useMemo } from 'react'
import { Field, Form } from 'react-final-form'
import { CompanySizeType } from '@medentee/enums'
import cls from 'classnames'
import { useTranslation } from 'react-i18next'

import { TCountry } from 'store/misc'
import { validation } from 'utils/validation'
import {
  TextField,
  Select,
  Button,
  TextFieldSkeleton,
  FormSubmitButtonSkeleton
} from 'App/components'
import { MAX_LENGTH_1000, MAX_HEADLINE_LENGTH, MAX_LENGTH_50 } from 'globalConstants'
import { TUserProfileBasicInformationPrivateFormContainerProps } from 'App/containers'

import styles from './UserProfileBasicInformationPrivateForm.module.scss'

export const UserProfileBasicInformationPrivateForm = ({
  country,
  firstName,
  lastName,
  about,
  headline,
  city,
  countries,
  cities,
  processing,
  loading,
  onSubmit,
  getCities,
  onFormChange
}: TUserProfileBasicInformationPrivateFormContainerProps) => {
  const { t } = useTranslation()

  useEffect(() => {
    country.code && getCities({ countryCode: country.code })
  }, [getCities, country])

  const initialFormValues = useMemo(
    () => ({
      firstName,
      lastName,
      about,
      headline,
      country,
      city
    }),
    [about, city, country, firstName, headline, lastName]
  )

  const onChange = () => onFormChange()

  return (
    <Form onSubmit={onSubmit} initialValues={initialFormValues}>
      {({ handleSubmit, form }) => (
        <form onSubmit={handleSubmit} className={styles.form} onChange={onChange}>
          <div className={cls(styles.formRow, loading && styles.formRowSkeleton)}>
            <TextFieldSkeleton loading={loading}>
              <Field
                name="firstName"
                validate={validation.composeValidators(
                  validation.required(),
                  validation.isPersonName('name'),
                  validation.maxLength(MAX_LENGTH_50)
                )}
              >
                {({ input, meta }) => (
                  <TextField
                    {...input}
                    invalid={meta.touched && meta.invalid}
                    error={meta.error}
                    classes={{ wrapper: styles.input }}
                    topLabel={t(
                      'userProfile.professionalAccountBasicInformationForm.fields.firstName'
                    )}
                    valueLengthMax={MAX_LENGTH_50}
                  />
                )}
              </Field>
            </TextFieldSkeleton>
            <TextFieldSkeleton loading={loading}>
              <Field
                name="lastName"
                validate={validation.composeValidators(
                  validation.required(),
                  validation.isPersonName('lastName'),
                  validation.maxLength(MAX_LENGTH_50)
                )}
              >
                {({ input, meta }) => (
                  <TextField
                    {...input}
                    invalid={meta.touched && meta.invalid}
                    error={meta.error}
                    classes={{ wrapper: styles.input }}
                    topLabel={t(
                      'userProfile.professionalAccountBasicInformationForm.fields.lastName'
                    )}
                    valueLengthMax={MAX_LENGTH_50}
                  />
                )}
              </Field>
            </TextFieldSkeleton>
          </div>
          <div className={cls(styles.formRow, loading && styles.formRowSkeleton)}>
            <TextFieldSkeleton loading={loading}>
              <Field name="country" validate={validation.required()}>
                {({ input, meta }) => {
                  const onInputChange = (value: TCountry) => {
                    onFormChange()
                    input.onChange(value)
                    form.change('city', undefined)
                    getCities({ countryCode: value.code })
                  }

                  return (
                    <Select
                      value={input.value}
                      options={countries}
                      labelKey="countryName"
                      valueKey="code"
                      invalid={meta.touched && meta.invalid}
                      error={meta.error}
                      wrapperClassName={styles.input}
                      topLabel={t(
                        'userProfile.professionalAccountBasicInformationForm.fields.country'
                      )}
                      onChange={onInputChange}
                    />
                  )
                }}
              </Field>
            </TextFieldSkeleton>
            <TextFieldSkeleton loading={loading}>
              <Field name="city" validate={validation.required()}>
                {({ input, meta }) => {
                  const onInputChange = (value: CompanySizeType) => {
                    onFormChange()
                    input.onChange(value)
                  }

                  return (
                    <Select
                      value={input.value}
                      options={cities}
                      labelKey="cityName"
                      valueKey="id"
                      invalid={meta.touched && meta.invalid}
                      error={meta.error}
                      wrapperClassName={styles.input}
                      topLabel={t(
                        'userProfile.professionalAccountBasicInformationForm.fields.city'
                      )}
                      onChange={onInputChange}
                    />
                  )
                }}
              </Field>
            </TextFieldSkeleton>
          </div>
          <div className={cls(styles.formRow, loading && styles.formRowSkeleton)}>
            <TextFieldSkeleton loading={loading} variant="textArea">
              <Field
                name="headline"
                validate={validation.composeValidators(
                  validation.onlySpaces(),
                  validation.maxLength(MAX_HEADLINE_LENGTH)
                )}
              >
                {({ input, meta }) => (
                  <TextField
                    {...input}
                    invalid={meta.touched && meta.invalid}
                    error={meta.error}
                    multiline={true}
                    rows={4}
                    rowsMax={4}
                    topLabel={t(
                      'userProfile.professionalAccountBasicInformationForm.fields.headline'
                    )}
                    valueLengthMax={MAX_HEADLINE_LENGTH}
                  />
                )}
              </Field>
            </TextFieldSkeleton>
          </div>
          <div className={cls(styles.formRow, loading && styles.formRowSkeleton)}>
            <TextFieldSkeleton loading={loading} variant="textArea">
              <Field
                name="about"
                validate={validation.composeValidators(
                  validation.onlySpaces(),
                  validation.maxLength(MAX_LENGTH_1000)
                )}
              >
                {({ input, meta }) => (
                  <TextField
                    {...input}
                    invalid={meta.touched && meta.invalid}
                    error={meta.error}
                    multiline={true}
                    rows={4}
                    rowsMax={4}
                    topLabel={t('userProfile.professionalAccountBasicInformationForm.fields.about')}
                    valueLengthMax={MAX_LENGTH_1000}
                  />
                )}
              </Field>
            </TextFieldSkeleton>
          </div>

          <FormSubmitButtonSkeleton loading={loading} className={styles.skeletonButton}>
            <Button
              type="submit"
              loading={processing}
              className={styles.button}
              disabled={form.getState().invalid}
            >
              {t('userProfile.professionalAccountBasicInformationForm.submitButton')}
            </Button>
          </FormSubmitButtonSkeleton>
        </form>
      )}
    </Form>
  )
}
