import { useCallback, useEffect, useReducer } from 'react'

import { TSearchBy } from 'api/contacts'
import { TAccountsSelectGroupsItem } from 'App/components/common/AccountsSelect/AccountsSelectGroups'
import { TAccountsSelectOptionData } from 'App/components/common/AccountsSelect/AccountsSelectOption/AccountsSelectOption'

import { useRefValue } from './useRefValue'

export type TUseSelectAccountsProps = {
  accounts: TAccountsSelectOptionData[]
  groups?: TAccountsSelectGroupsItem[]
  onSubmit: (value: TAccountsSelectOptionData[]) => void
}

type TActions =
  | { type: 'SET_PAGE'; payload: number }
  | { type: 'SET_SEARCH'; payload: string }
  | { type: 'SET_SEARCH_BY'; payload: TSearchBy }
  | { type: 'SET_SELECTED_ACCOUNTS'; payload: TAccountsSelectOptionData[] }
  | { type: 'PUSH_SELECTED_ACCOUNTS'; payload: TAccountsSelectOptionData[] }
  | { type: 'UNSELECT_ACCOUNTS' }
  | { type: 'UNSELECT_ACCOUNT'; payload: string }
  | { type: 'SELECT_ACCOUNT'; payload: TAccountsSelectOptionData }

type TState = {
  idle: boolean
  page: number
  selectedAccounts: TAccountsSelectOptionData[]
  search: string
  searchBy: TSearchBy
}

const initialState: TState = {
  idle: true,
  page: 0,
  search: '',
  searchBy: {
    group: null,
    id: null
  },
  selectedAccounts: []
}

const reducer = (state = initialState, action: TActions): TState => {
  switch (action.type) {
    case 'SET_PAGE':
      return {
        ...state,
        page: action.payload
      }

    case 'SET_SEARCH':
      return {
        ...state,
        page: 0,
        search: action.payload
      }

    case 'SET_SEARCH_BY':
      return {
        ...state,
        page: 0,
        searchBy: action.payload
      }

    case 'SET_SELECTED_ACCOUNTS':
      return {
        ...state,
        idle: false,
        selectedAccounts: [...action.payload]
      }

    case 'PUSH_SELECTED_ACCOUNTS':
      return {
        ...state,
        idle: false,
        selectedAccounts: [...action.payload, ...state.selectedAccounts]
      }

    case 'UNSELECT_ACCOUNTS': {
      return {
        ...state,
        idle: false,
        selectedAccounts: state.selectedAccounts.filter(({ disabled }) => disabled)
      }
    }

    case 'UNSELECT_ACCOUNT':
      return {
        ...state,
        idle: false,
        selectedAccounts: state.selectedAccounts.filter((item) => item.id !== action.payload)
      }

    case 'SELECT_ACCOUNT':
      return {
        ...state,
        idle: false,
        selectedAccounts: [action.payload, ...state.selectedAccounts]
      }

    default:
      return state
  }
}

export const useSelectAccounts = ({ groups, onSubmit, accounts }: TUseSelectAccountsProps) => {
  const [state, dispatch] = useReducer(reducer, { ...initialState, selectedAccounts: accounts })
  const { getValue: getAccounts } = useRefValue(accounts)

  const handleSubmit = () => {
    onSubmit(state.selectedAccounts)
  }

  const onSelectAll = useCallback(
    (payload: TAccountsSelectOptionData[]) => dispatch({ type: 'PUSH_SELECTED_ACCOUNTS', payload }),
    []
  )

  const onSelectGroup = useCallback(
    (payload: TAccountsSelectOptionData[]) => dispatch({ type: 'SET_SELECTED_ACCOUNTS', payload }),
    []
  )

  const onUnselectAll = useCallback(() => dispatch({ type: 'UNSELECT_ACCOUNTS' }), [])

  const onSelect = useCallback(
    (item: TAccountsSelectOptionData) => dispatch({ type: 'SELECT_ACCOUNT', payload: item }),
    []
  )

  const onUnselect = useCallback(
    (id: string) => dispatch({ type: 'UNSELECT_ACCOUNT', payload: id }),
    []
  )

  const onSearch = useCallback(
    (value: string) => dispatch({ type: 'SET_SEARCH', payload: value }),
    []
  )

  const onSearchBy = useCallback(
    (value: TSearchBy) => dispatch({ type: 'SET_SEARCH_BY', payload: value }),
    []
  )

  const onPageChange = useCallback(
    (page: number) => dispatch({ type: 'SET_PAGE', payload: page }),
    []
  )

  // adjust selected groups
  useEffect(() => {
    if (groups?.length) {
      dispatch({
        type: 'SET_SELECTED_ACCOUNTS',
        payload: getAccounts().reduce<TAccountsSelectOptionData[]>((res, acc) => {
          const group = groups?.find((gr) => gr.value === acc.id)

          if (group && group.items?.length) {
            return [
              ...res,
              ...group.items.map((item) => ({ displayUserName: item.label, id: item.value }))
            ]
          }

          return [...res, acc]
        }, [])
      })
    }
  }, [groups, getAccounts])

  return {
    state,
    handleSubmit,
    onSelectAll,
    onUnselectAll,
    onSelect,
    onSelectGroup,
    onUnselect,
    onSearch,
    onSearchBy,
    onPageChange
  }
}
