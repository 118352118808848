import { useHistory } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { Skeleton } from 'antd'
import { useTranslation } from 'react-i18next'

import { getCommunity } from 'api'
import { getCommunityBasePathSelector, useAppSelector } from 'store'

import { Button } from '../../../common'

export type TUnityHubViewCommunityButtonProps = {
  communityId: string
}

export const UnityHubViewCommunityButton = ({ communityId }: TUnityHubViewCommunityButtonProps) => {
  const { push } = useHistory()
  const { t } = useTranslation()
  const communityBasePath = useAppSelector(getCommunityBasePathSelector(communityId))

  const { data, isFetching } = useQuery(
    ['get-community', communityId],
    () => getCommunity({ id: communityId }),
    {
      enabled: !!communityId
    }
  )

  const handleClick = data
    ? () => {
        if (data?.isCommunityMember) {
          push(communityBasePath)
        } else {
          push(`/communities/${communityId}/p/info`)
        }
      }
    : undefined

  return (
    <Skeleton active loading={isFetching} title={false} paragraph={{ rows: 1 }}>
      <Button variant="outlined" size="sm" onClick={handleClick}>
        {t('unityHub.info.viewCommunityButton')}
      </Button>
    </Skeleton>
  )
}
