import { useHistory } from 'react-router'
import cls from 'classnames'
import { useTranslation } from 'react-i18next'

import { ReactComponent as ChevronLeftIcon } from 'assets/icons/ChevronLeft.svg'
import { Button } from 'App/components/common/Button'

import styles from './UnityHubBackButton.module.scss'

type TUnityHubBackButtonProps = PropsWithClassName<{
  path: string

  label?: string
}>

export const UnityHubBackButton = ({ path, className, label }: TUnityHubBackButtonProps) => {
  const { push } = useHistory()

  const { t } = useTranslation()

  return (
    <div className={cls(styles.root, className)}>
      <Button
        icon={<ChevronLeftIcon />}
        variant="inline"
        fontWeight="bold"
        className={styles.button}
        onClick={() => push(path)}
      >
        {label ?? t('unityHub.goToChannelsButton')}
      </Button>
    </div>
  )
}
