import {
  TUserProfileSocialLinksProps,
  UserProfileSocialLinks
} from 'App/components/UserProfile/UserProfileSocialLinks'

import {
  UnityHubInfoOwnerAccount,
  TUnityHubInfoOwnerAccountProps
} from '../UnityHubInfoOwnerAccount'

import styles from './UnityHubInfoOwner.module.scss'

export type TUnityHubInfoOwnerProps = {
  organizer: TUnityHubInfoOwnerAccountProps

  communityId?: string
  provider?: TUnityHubInfoOwnerAccountProps
  socialLinks?: TUserProfileSocialLinksProps['links']
}

export const UnityHubInfoOwner = ({
  socialLinks,
  organizer,
  provider,
  communityId
}: TUnityHubInfoOwnerProps) => (
  <div className={styles.root}>
    <div className={styles.organizer}>
      <UnityHubInfoOwnerAccount
        displayUserName={organizer.displayUserName}
        firstName={organizer.firstName}
        lastName={organizer.lastName}
        userId={organizer.userId}
        isCommunity={organizer.isCommunity}
        isEventOrganizer={organizer.isEventOrganizer}
        shouldRedirectToUserProfile={organizer.shouldRedirectToUserProfile}
        title={organizer.title}
        label={organizer.label}
        communityId={organizer.userId === communityId ? communityId : undefined}
        eventId={organizer.eventId}
      />

      {!!socialLinks?.length && (
        <UserProfileSocialLinks className={styles.socialLinks} links={socialLinks} size="md" />
      )}
    </div>

    {provider && (
      <UnityHubInfoOwnerAccount
        displayUserName={provider.displayUserName}
        firstName={provider.firstName}
        lastName={provider.lastName}
        userId={provider.userId}
        isCommunity={provider.isCommunity}
        shouldRedirectToUserProfile={provider.shouldRedirectToUserProfile}
        title={provider.title}
        label={provider.label}
        communityId={provider.userId === communityId ? communityId : undefined}
      />
    )}
  </div>
)
