import { connect } from 'react-redux'

import { State } from 'redux/rootReducer'
import { getChatSenderName } from 'utils'
import { ChatMessageText, TChatMessageTextBaseProps } from 'App/components'
import { isMessageOutgoingSelector } from 'store/chatMessages'

export type TChatMessageTextContainerProps = ReturnType<typeof mapStateToProps>

const mapStateToProps = (state: State, { id, showUserName, chatId }: TChatMessageTextBaseProps) => {
  const message = state.chat.chatMessages.messages.list[id] ?? {}
  const chatRoomType = state.chat.chatRooms.list[chatId]?.type

  const senderName = getChatSenderName({
    senderName: message?.sender?.displayUserName,
    showUserName,
    yourMessage: message?.yourMessage
  })

  return {
    message: message?.message,
    quoteMessage: message?.quoteMessage,
    senderName,
    mentionedAccounts: message.mentionedAccounts,
    mentionedDepartments: message.mentionedDepartments,
    chatRoomType,
    isMessageOutgoing: isMessageOutgoingSelector(message.yourMessage)(state)
  }
}

export const ChatMessageTextContainer = connect(mapStateToProps)(ChatMessageText)
