import { memo, useState } from 'react'
import { EditorState } from 'draft-js'

import { DraftEditor, useDraftEditor, TDraftEditorProps } from 'App/components'
import { FileSuggestion } from 'App/components/common/DraftEditor/FileSuggestion'
import { createMentionEntities, TTrigger, wrapMentions } from 'utils/mentions'
import { MAX_LENGTH_500 } from 'globalConstants'
import { createMentionPlugin } from 'App/components/common/DraftEditor/Mention/Mention.utils'

import { TUseFileSuggestionsResponse } from '../../useFileSuggestions'

import styles from './DescriptionField.module.scss'

export type TOnSearchChangePayload = {
  trigger: TTrigger
  value: string
}

export type TDescriptionFieldProps = Pick<TDraftEditorProps, 'invalid' | 'error'> &
  TUseFileSuggestionsResponse & {
    onChange: (text: string) => void

    initialValue?: string
  }

const { Suggestions, plugins } = createMentionPlugin({
  mentionTrigger: '/',
  mentionPrefix: '/'
})

const DescriptionFieldView = ({
  error,
  invalid,
  onSearchChange,
  onChange,
  initialValue = '',
  fileMentions = [],
  fileSuggestions = []
}: TDescriptionFieldProps) => {
  const [open, setOpen] = useState<boolean>(false)

  const { editorState, plainText, setEditorState } = useDraftEditor({
    draftMessage: createMentionEntities(initialValue, fileMentions)
  })

  const onEditorChange = (editorValue: EditorState) => {
    const message = editorValue.getCurrentContent().getPlainText()

    setEditorState(editorValue)

    if (!message.trim()) {
      setOpen(false)
    }

    if (message !== plainText) {
      const formattedMessage = wrapMentions(editorValue)

      onChange(formattedMessage)
    }
  }

  return (
    <div className={styles.root}>
      <DraftEditor
        editorState={editorState}
        onChange={onEditorChange}
        classes={{
          editorContainer: styles.editorContainer,
          editorWrapper: styles.editorWrapper
        }}
        valueLengthMax={MAX_LENGTH_500}
        showMaxLength={true}
        plugins={plugins}
        withEmoji={false}
        showFocus={true}
        error={error}
        invalid={invalid}
        focusOnMount={false}
      />
      <Suggestions
        open={open}
        suggestions={fileSuggestions}
        entryComponent={FileSuggestion}
        onOpenChange={setOpen}
        onSearchChange={onSearchChange}
      />
    </div>
  )
}

export const DescriptionField = memo(DescriptionFieldView)
