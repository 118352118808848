import { useCallback, useMemo } from 'react'
import { ContactStatusEnum } from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import { useMutation } from 'services/query'
import { TMenuItemProps } from 'App/components/common/MenuList'
import { showModalAction, useAppDispatch } from 'store'
import { EModalComponents } from 'App/containers'
import { queryClient } from 'queryClient'
import { ReactComponent as AccountPlusOutlineIcon } from 'assets/icons/AccountPlusOutline.svg'
import { ReactComponent as AccountRemoveOutlineIcon } from 'assets/icons/AccountRemoveOutline.svg'
import { ReactComponent as AccountMultiplePlusOutlineIcon } from 'assets/icons/AccountMultiplePlusOutline.svg'
import { deleteAttendeeEventProfile } from 'api/events'
import { useContactInvitation } from 'App/hooks/useContactInvitation'

import { EVENT_ATTENDEES_LIST_QUERY_KEY } from '../EventNetworkingList'

type TMenuItemsProps = {
  userId: string
  userName: string

  toId?: string
  contactId?: string
  attendeeId?: string
  status?: ContactStatusEnum
  chatId?: string
  disableInvitation?: boolean
  isAdmin?: boolean
}

type TMenuItemKey =
  | 'inviteToContact'
  | 'withdrawInvitation'
  | 'approveInvitation'
  | 'rejectInvitation'
  | 'remove'

export type TUseKebabMenu = {
  getMenuItems: (props: TMenuItemsProps) => TMenuItemProps[]
}

type TUseEventNetworkingListItemKebabMenuProps = {
  eventId: string
  isAdmin: boolean
}

export const REMOVE_MEMBER_QUERY_KEY = 'remove_member'

export const useEventNetworkingListItemKebabMenu = ({
  eventId,
  isAdmin
}: TUseEventNetworkingListItemKebabMenuProps): TUseKebabMenu => {
  const dispatch = useAppDispatch()

  const { t } = useTranslation()

  const onSuccess = useCallback(() => {
    queryClient.invalidateQueries({ queryKey: [EVENT_ATTENDEES_LIST_QUERY_KEY], type: 'active' })
  }, [])

  const {
    withdrawContactInvitation,
    acceptContactInvitation,
    rejectContactInvitation,
    inviteToContacts
  } = useContactInvitation()

  const { mutateAsync: removeAttendee } = useMutation({
    mutationKey: ['remove-attendee'],
    mutationFn: (id: string) => deleteAttendeeEventProfile({ attendeeId: id, eventId }),
    onSuccess
  })

  const handleRemoveAttendee = useCallback(
    (attendeeId: string) =>
      dispatch(
        showModalAction({
          modalTitle: t('modal.removeEventAttendeeConfirm.title'),
          modalType: EModalComponents.GENERIC_CONFIRMATION,
          modalProps: {
            confirmLabel: t('modal.removeEventAttendeeConfirm.submitButton'),
            content: t('modal.removeEventAttendeeConfirm.content'),
            onConfirm: () => removeAttendee(attendeeId)
          }
        })
      ),
    [dispatch, removeAttendee, t]
  )

  const menuItems = useMemo<Record<TMenuItemKey, (props: TMenuItemsProps) => TMenuItemProps>>(
    () => ({
      inviteToContact: ({ status, userId, disableInvitation }) => ({
        hidden:
          status === ContactStatusEnum.PENDING ||
          status === ContactStatusEnum.APPROVED ||
          disableInvitation,
        content: t('unityHub.networking.member.kebabMenu.inviteToContacts'),
        icon: <AccountPlusOutlineIcon />,
        onClick: () => inviteToContacts({ accountId: userId, onSuccess })
      }),
      withdrawInvitation: ({ status, userId, toId, contactId, disableInvitation }) => ({
        hidden:
          status !== ContactStatusEnum.PENDING || (!!toId && toId !== userId) || disableInvitation,
        content: t('unityHub.networking.member.kebabMenu.withdrawInvitation'),
        icon: <AccountRemoveOutlineIcon />,
        onClick: () => contactId && withdrawContactInvitation({ contactId, onSuccess })
      }),
      approveInvitation: ({ status, userId, toId, disableInvitation }) => ({
        hidden:
          status !== ContactStatusEnum.PENDING || (!!toId && toId === userId) || disableInvitation,
        content: t('unityHub.networking.member.kebabMenu.approveInvitation'),
        icon: <AccountMultiplePlusOutlineIcon />,
        onClick: () => acceptContactInvitation({ accountId: userId, onSuccess })
      }),
      rejectInvitation: ({ userId, status, toId, contactId, disableInvitation }) => ({
        hidden:
          status !== ContactStatusEnum.PENDING || (!!toId && toId === userId) || disableInvitation,
        content: t('unityHub.networking.member.kebabMenu.rejectInvitation'),
        icon: <AccountRemoveOutlineIcon />,
        onClick: () => contactId && rejectContactInvitation({ contactId, onSuccess })
      }),
      remove: ({ attendeeId }) => ({
        hidden: !isAdmin,
        content: t('events.networking.attendee.drawer.kebabMenu.removeMember'),
        icon: <AccountRemoveOutlineIcon />,
        onClick: () => attendeeId && handleRemoveAttendee(attendeeId)
      })
    }),
    [
      acceptContactInvitation,
      handleRemoveAttendee,
      inviteToContacts,
      isAdmin,
      onSuccess,
      rejectContactInvitation,
      t,
      withdrawContactInvitation
    ]
  )

  const getMenuItems = useCallback(
    (props: TMenuItemsProps) => [
      menuItems.inviteToContact(props),
      menuItems.withdrawInvitation(props),
      menuItems.approveInvitation(props),
      menuItems.rejectInvitation(props),
      menuItems.remove(props)
    ],
    [menuItems]
  )

  return {
    getMenuItems
  }
}
