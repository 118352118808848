import { MessageTypeEnum } from '@medentee/enums'

import { TChatMessagesNormalized, TIds } from 'store'

import {
  TChatBaseMessage,
  TChatReactionList,
  TChatReactionsMessageList,
  TChatReactionsOutgoingList,
  TChatReactionsAccountList,
  TChatMessagesList
} from './chatMessages.types'

export const chatMessagesReactionsNormalize = (
  originalData: TChatBaseMessage[],
  currentAccountId?: string
) => {
  const messageList: TChatReactionsMessageList = {}
  const outgoingList: TChatReactionsOutgoingList = {}
  const accountList: TChatReactionsAccountList = {}

  originalData.forEach((message) => {
    const { reactions } = message
    const list: TChatReactionList = {}

    reactions?.forEach((item) => {
      list[item.reaction] = list[item.reaction]
        ? list[item.reaction].add(item.accountId)
        : new Set([item.accountId])

      accountList[item.accountId] = item.account

      if (currentAccountId === item.accountId) {
        outgoingList[message.id] = item.reaction
      }
    })

    const emojis = new Set<string>(Object.keys(list))

    if (emojis.size) {
      messageList[message.id] = {
        emojis,
        list
      }
    }
  })

  return {
    messageList,
    outgoingList,
    accountList
  }
}

export const chatMessagesNormalize = (
  originalData: TChatBaseMessage[]
): TChatMessagesNormalized => {
  const list: TChatMessagesList = {}
  const ids: TIds = []

  originalData.forEach((item) => {
    if (item.type === MessageTypeEnum.DELETED) {
      return
    }

    ids.push(item.id)

    list[item.id] = {
      ...item,
      // `reactions` are parsed separately and stored separately as well
      reactions: undefined
    }
  })

  return {
    list,
    ids
  }
}
