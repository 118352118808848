import cls from 'classnames'
import { useTranslation } from 'react-i18next'

import { FileViewerProgress, Image } from 'App/components/common'
import { useDownloadFile, TUseDownloadFileProps } from 'App/hooks'

import styles from './ImageViewer.module.scss'

export type TImageViewerProps = Pick<
  TUseDownloadFileProps,
  'id' | 'fileSrc' | 'originalEntityId' | 'source'
> &
  PropsWithClasses<'root' | 'image'>

export const ImageViewer = ({
  id,
  source,
  originalEntityId,
  classes,
  fileSrc
}: TImageViewerProps) => {
  const { progress, error, src } = useDownloadFile({
    source,
    id,
    originalEntityId,
    fileSrc
  })

  const { t } = useTranslation()

  return (
    <div className={cls(styles.root, classes?.root)}>
      <Image
        className={cls(styles.image, classes?.image)}
        src={src}
        alt=""
        placeholder={
          <FileViewerProgress
            percent={progress}
            label={!!error ? t('common.fileViewer.failure') : undefined}
            status={!!error ? 'exception' : undefined}
          />
        }
        hidden={progress !== 100}
      />
    </div>
  )
}
