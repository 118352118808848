import { useCallback, useEffect } from 'react'
import { useHistory, useParams, useRouteMatch } from 'react-router-dom'
import { AccountTypeNames, ErrorCodesEnum } from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import { useMutation, useQuery } from 'services/query'
import { getCommunity, joinCommunity } from 'api'
import { getDataByInvitationToken } from 'api/profile'
import { showModalAction, useAppDispatch, useAppSelector } from 'store'
import { toast } from 'App/components/ToastContainer'
import { EModalComponents } from 'App/containers/ModalRoot/ModalRoot.enums'
import { toastDefaultOptions } from 'globalConstants'
import { isMatchErrorCode } from 'utils'
import { useAdaptiveLayout } from 'App/hooks/useAdaptiveLayout'

type TParams = {
  id?: string
  token?: string
}

const COMMUNITY_PATH = '/community'
const COMMUNITIES_PATH = '/communities'
const COMMUNITIES_JOIN_PATH = '/communities/t/info/:token'

export const useCommunityInfoRequests = (id?: string) => {
  const dispatch = useAppDispatch()

  const { t } = useTranslation()

  const { token = '', ...params } = useParams<TParams>()
  const { push, replace } = useHistory()

  const match = useRouteMatch([COMMUNITY_PATH, COMMUNITIES_PATH])
  const joinMatch = useRouteMatch(COMMUNITIES_JOIN_PATH)

  const { isDesktop } = useAdaptiveLayout()

  const isProfessionalAccount = useAppSelector(
    (state) => state.global.accountData?.type.name === AccountTypeNames.PROFESSIONAL
  )

  const urlCommunityId = id ?? params.id

  const onRequestError = () => {
    toast.error(
      t('serverError.DEFAULT_NOT_AVAILABLE_RESOURCE_MESSAGE', { ns: 'errors' }),
      toastDefaultOptions
    )
    replace(isProfessionalAccount ? COMMUNITIES_PATH : COMMUNITY_PATH)
  }

  const { data, isFetching } = useQuery({
    queryKey: ['get-community', urlCommunityId],
    queryFn: urlCommunityId ? () => getCommunity({ id: urlCommunityId }) : undefined,
    enabled: Boolean(urlCommunityId),
    onError: onRequestError
  })

  const invitationResponse = useQuery({
    queryKey: ['get-data-by-token'],
    queryFn: () => getDataByInvitationToken(token),
    cacheTime: 0,
    enabled: Boolean(token),
    onError: (err) => {
      if (isMatchErrorCode(err, ErrorCodesEnum.INVITATION_NOT_EXIST)) {
        toast.error(t('serverError.INVITATION_NOT_EXIST', { ns: 'errors' }), toastDefaultOptions)
      }

      onRequestError()
    }
  })

  const communityId = (urlCommunityId || invitationResponse.data?.community?.id) ?? ''
  const isCommunityMember = invitationResponse.data?.isCommunityMember || data?.isCommunityMember

  const shouldShowJoin = Boolean(
    isProfessionalAccount &&
      ((invitationResponse.data?.community?.id && !isCommunityMember) || data?.isJoinAvailable)
  )

  const shouldShowSwitchModal =
    invitationResponse.data?.community?.id && token && !isProfessionalAccount

  const shouldShowGoToChannels =
    !shouldShowJoin && !shouldShowSwitchModal && (Boolean(joinMatch?.isExact) || !isDesktop)

  const { mutate, isLoading } = useMutation({
    mutationKey: ['join-to-community'],
    mutationFn: joinCommunity,
    onSuccess: () => {
      toast.success(t('common.toast.joinedSuccessfully'), toastDefaultOptions)
      replace(`${COMMUNITIES_PATH}/${communityId}/info`)
    }
  })

  const handleGoToChannels = useCallback(() => {
    if (!match?.path) {
      return
    }

    push(match.path === COMMUNITY_PATH ? COMMUNITY_PATH : `${COMMUNITIES_PATH}/${communityId}`)
  }, [push, communityId, match])

  const handleJoinCommunity = useCallback(
    () => mutate({ communityId, invitationToken: token }),
    [communityId, mutate, token]
  )

  useEffect(() => {
    if (shouldShowSwitchModal) {
      dispatch(
        showModalAction({
          modalType: EModalComponents.SWITCH_ACCOUNT_DIALOG,
          modalTitle: t('modal.switchAccountConfirm.title'),
          modalProps: {
            onSuccess: () => {
              window.location.href = `${window.location.origin}${COMMUNITIES_PATH}/t/info/${token}`
            }
          }
        })
      )
    }
  }, [token, dispatch, shouldShowSwitchModal, t])

  return {
    isCommunityMember,
    token,
    handleGoToChannels,
    handleJoinCommunity,
    isLoading,
    data: data ?? invitationResponse.data?.community,
    communityId,
    shouldShowJoin,
    shouldShowGoToChannels,
    isFetching: isFetching || invitationResponse.isFetching
  }
}
