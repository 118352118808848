import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import cls from 'classnames'
import { Row } from 'antd'
import { UploadSourcesEnum } from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import { EInputSize } from 'enums'
import { TAttachMedcloudFilesList, useAppSelector } from 'store'
import { formatBytes } from 'utils'
import {
  Search,
  ErrorModal,
  EmptyList,
  StepsFooterActions,
  Alert,
  FileViewToggle,
  TFilesViewVariant,
  InfiniteScroll
} from 'App/components'
import { usePrevious } from 'App/hooks'
import {
  DragAndDropContainer,
  TCaseAttachFileDialogContainerProps,
  TChatAttachFileDialogContainerProps
} from 'App/containers'
import { ReactComponent as TextBoxIcon } from 'assets/icons/TextBox.svg'

import { Actions } from './Actions'
import styles from './FileAttachDialog.module.scss'
import { FileAttachWrapper } from './FileAttachWrapper'
import { FileAttachItem } from './FileAttachItem'

export type TFileAttachDialogPropsOwnProps = {
  caseId?: string
  chatId?: string
  submitDisabled?: boolean
  isBroadcast?: boolean
  maxFileSize?: number
  attachedFiles?: TAttachMedcloudFilesList
  onBack?: () => void
  attachSelectedFilesToBroadcast?: () => void
}

export type TFileAttachDialogProps = PartialBy<
  TCaseAttachFileDialogContainerProps & TChatAttachFileDialogContainerProps,
  | 'attachSelectedFilesToCase'
  | 'attachSelectedFilesToChat'
  | 'getCaseMedCloudFiles'
  | 'getChatMedCloudFiles'
> &
  TFileAttachDialogPropsOwnProps

const LIST_CONFIG = {
  HEIGHT: 360,
  SCROLLABLE_TARGET_CLASS_NAME: 'file-attach-dialog'
}

export const FileAttachDialog: FC<TFileAttachDialogProps> = ({
  processing,
  loading,
  error,
  caseId,
  chatId,
  selectedFileIds,
  fileIds,
  total,
  uploadFileIds,
  search,
  isBroadcast,
  submitDisabled,
  removeErrorNotification,
  getCaseMedCloudFiles,
  getChatMedCloudFiles,
  attachSelectedFilesToChat,
  attachSelectedFilesToCase,
  hideAttachModal,
  onBack,
  attachSelectedFilesToBroadcast,
  maxFileSize,
  uploadedFrom = UploadSourcesEnum.CLOUD,
  attachedFiles
}) => {
  const { t } = useTranslation()

  const [initialLoading, setInitialLoading] = useState<boolean>(true)
  const [filesViewVariant, setFilesViewVariant] = useState<TFilesViewVariant>('grid')

  const filters = useAppSelector((state) => state.medCloud.filters) ?? {}
  const errors = useAppSelector((state) => state.medCloud.widget.errors || {})
  const filesInProgress = useAppSelector((state) => state.medCloud.upload.filesInProgress)

  const isValid = useMemo(
    () => (isBroadcast ? !selectedFileIds.every((item) => item in errors) : true),
    [selectedFileIds, isBroadcast, errors]
  )
  const isUploading = useMemo(
    () => (isBroadcast ? filesInProgress.some((item) => selectedFileIds.includes(item)) : false),
    [filesInProgress, isBroadcast, selectedFileIds]
  )
  const formatUpload = useMemo(
    () => uploadFileIds.map((id) => ({ id, upload: true })),
    [uploadFileIds]
  )
  const formatFiles = useMemo(() => fileIds.map((id) => ({ id, upload: false })), [fileIds])
  const data = useMemo(() => formatUpload.concat(formatFiles), [formatFiles, formatUpload])

  const prevLoading = usePrevious<boolean>(loading)

  const isFileIdsExist = fileIds.length > 0 || uploadFileIds.length > 0

  const shouldShowDragAndDrop = !loading && !isFileIdsExist && !search
  const shouldShowPlaceholder = !loading && !isFileIdsExist && !!search
  const shouldShowStepsFooterActions = isBroadcast || isFileIdsExist

  const handleGetMedCloudFiles = useCallback(
    (page = 0, searchValue: string = search ?? '') => {
      if (caseId && getCaseMedCloudFiles) {
        getCaseMedCloudFiles({ caseId, filters: { page, search: searchValue } })
        return
      }

      if (getChatMedCloudFiles) {
        getChatMedCloudFiles({ filters: { page, search: searchValue, maxFileSize } })
      }
    },
    [caseId, getCaseMedCloudFiles, getChatMedCloudFiles, maxFileSize, search]
  )

  const handleChange = useCallback(
    (value: string) => {
      handleGetMedCloudFiles(0, value)
    },
    [handleGetMedCloudFiles]
  )

  useEffect(() => {
    handleGetMedCloudFiles()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(
    () => () => {
      hideAttachModal()
      removeErrorNotification()
    },
    [hideAttachModal, removeErrorNotification]
  )

  useEffect(() => {
    if (initialLoading && prevLoading && !loading) {
      setInitialLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, setInitialLoading])

  const onAttach = useCallback(() => {
    if (caseId && attachSelectedFilesToCase) {
      attachSelectedFilesToCase({ caseId })
      return
    }

    if (attachSelectedFilesToBroadcast) {
      attachSelectedFilesToBroadcast()
      return
    }

    if (chatId && attachSelectedFilesToChat) {
      attachSelectedFilesToChat({ chatId })
    }
  }, [
    attachSelectedFilesToBroadcast,
    attachSelectedFilesToCase,
    attachSelectedFilesToChat,
    caseId,
    chatId
  ])

  return (
    <div className={cls(styles.root, { [styles.error]: !!error })}>
      {maxFileSize && (
        <Alert variant="info">
          {t('modal.attachFile.maxFileSizeAlert', { size: formatBytes(maxFileSize) })}
        </Alert>
      )}
      {!shouldShowDragAndDrop && (
        <>
          <Actions
            caseId={caseId}
            chatId={chatId}
            maxFileSize={maxFileSize}
            uploadedFrom={uploadedFrom}
            isBroadcast={isBroadcast}
          />

          <div className={styles.search}>
            <Search
              searchSize={EInputSize.L}
              placeholder={t('modal.attachFile.searchPlaceholder')}
              onChange={handleChange}
            />
          </div>
        </>
      )}

      <DragAndDropContainer
        chatId={chatId}
        caseId={caseId}
        uploadedFrom={uploadedFrom}
        isBroadcast={isBroadcast}
      >
        {shouldShowPlaceholder && (
          <div className={styles.emptySearch}>
            {t('common.field.search.listPlaceholder_search')}
          </div>
        )}

        {isFileIdsExist && (
          <>
            <Row className={styles.listHeader} justify="space-between" align="middle">
              <span className={styles.listTitle}>{t('modal.attachFile.medCloudLabel')}</span>
              <FileViewToggle variant={filesViewVariant} onChange={setFilesViewVariant} />
            </Row>

            <div className={LIST_CONFIG.SCROLLABLE_TARGET_CLASS_NAME}>
              <InfiniteScroll
                nestIn="parent"
                scrollableTarget={LIST_CONFIG.SCROLLABLE_TARGET_CLASS_NAME}
                next={handleGetMedCloudFiles}
                loading={loading}
                total={total + uploadFileIds.length}
                page={filters.page ?? 0}
                dataLength={data.length}
                height={LIST_CONFIG.HEIGHT}
              >
                <FileAttachWrapper view={filesViewVariant} data={data} loading={loading}>
                  {({ id, upload }) => (
                    <FileAttachItem
                      view={filesViewVariant}
                      key={id}
                      id={id}
                      loading={loading}
                      upload={!!upload}
                      isAttached={!!attachedFiles && id in attachedFiles}
                    />
                  )}
                </FileAttachWrapper>
              </InfiniteScroll>
            </div>
          </>
        )}

        {shouldShowDragAndDrop && (
          <EmptyList
            className={styles.empty}
            text={t('modal.attachFile.placeholder')}
            icon={<TextBoxIcon />}
          >
            <Actions
              caseId={caseId}
              chatId={chatId}
              uploadedFrom={uploadedFrom}
              isEmpty={true}
              isBroadcast={isBroadcast}
            />
          </EmptyList>
        )}
      </DragAndDropContainer>

      {shouldShowStepsFooterActions && (
        <StepsFooterActions
          className={styles.button}
          onSubmit={onAttach}
          submitLoading={isUploading || processing}
          submitDisabled={!isValid || submitDisabled || !selectedFileIds.length}
          submitLabel={t('modal.attachFile.submitButton', {
            postProcess: 'interval',
            count: selectedFileIds.length
          })}
          onClickBack={onBack}
          backType="button"
        />
      )}

      <ErrorModal error={error} />
    </div>
  )
}
