import { FileHistorySourcesEnum } from '@medentee/enums'
import { useCallback } from 'react'

import { TFileViewerProps } from 'App/components'
import {
  createProcessingSelector,
  downloadCaseFileRequest,
  downloadFileFromChatRequest,
  downloadFileRequest,
  useAppDispatch,
  useAppSelector,
  DOWNLOAD_CASE_FILE,
  DOWNLOAD_FILE,
  DOWNLOAD_FILE_FROM_CHAT
} from 'store'
import { downloadFileFromUrl } from 'utils'

const processingSelector = createProcessingSelector([
  DOWNLOAD_CASE_FILE,
  DOWNLOAD_FILE,
  DOWNLOAD_FILE_FROM_CHAT
])

export const useDownload = ({
  id,
  source,
  originalEntityId,
  fileSrc
}: Pick<TFileViewerProps, 'id' | 'originalEntityId' | 'source' | 'fileSrc'>) => {
  const dispatch = useAppDispatch()
  const processing = useAppSelector(processingSelector)

  const onDownload = useCallback(() => {
    if (fileSrc) {
      downloadFileFromUrl({ content: fileSrc })

      return
    }

    switch (source) {
      case FileHistorySourcesEnum.MED_CLOUD:
        return dispatch(downloadFileRequest({ fileId: id }))

      case FileHistorySourcesEnum.CASE_CLOUD: {
        if (!originalEntityId) {
          return
        }

        return dispatch(downloadCaseFileRequest({ fileId: id, caseId: originalEntityId }))
      }

      case FileHistorySourcesEnum.P2P_CLOUD: {
        if (!originalEntityId) {
          return
        }

        return dispatch(downloadFileFromChatRequest({ fileId: id, originalEntityId }))
      }

      default:
        return
    }
  }, [fileSrc, source, dispatch, id, originalEntityId])

  return { processing, onDownload }
}
