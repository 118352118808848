import { AccountTypeNames } from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import { useAppSelector } from 'store'
import { BUSINESS_ACCOUNT_LIMIT } from 'globalConstants'

import styles from './Placeholder.module.scss'

const MAX_ACCOUNT_NUMBER = 3

type TPlaceholder = {
  type?: AccountTypeNames
}

export const Placeholder = ({ type }: TPlaceholder) => {
  const { list } = useAppSelector((state) => state.userProfile.relatedAccounts)

  const { t } = useTranslation()

  const businessAccNumber = Object.values(list).filter(
    (item) => item.type.name === AccountTypeNames.BUSINESS
  )

  const showPlaceholder =
    businessAccNumber.length >= MAX_ACCOUNT_NUMBER && type === AccountTypeNames.PROFESSIONAL

  if (!showPlaceholder) {
    return null
  }

  return (
    <p className={styles.root}>
      {t('relatedAccounts.placeholder', { limit: BUSINESS_ACCOUNT_LIMIT })}
    </p>
  )
}
