import { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from 'react'
import Editor, { PluginEditorProps } from '@draft-js-plugins/editor'
import addEmoji from 'draft-js-emoji-mart-plugin/lib/modifiers/addEmoji'
import cls from 'classnames'
import { DraftHandleValue, EditorState, Modifier } from 'draft-js'
import { Col, Row } from 'antd'
import { useTranslation } from 'react-i18next'

import { EIconSize } from 'enums'
import { ClickAwayListener, HelperText, IconButton, LengthCounter } from 'App/components'
import { Error as ErrorHelper, TErrorProps } from 'App/components/common/Fields/Error'
import { useAdaptiveLayout } from 'App/hooks'
import { MAX_DRAFT_LENGTH } from 'globalConstants'
import { ReactComponent as EmojiIcon } from 'assets/icons/Emoji.svg'
import '@draft-js-plugins/mention/lib/plugin.css'

import { EmojiPicker, emojiPlugin } from '../EmojiPicker'

import styles from './DraftEditor.module.scss'

export type TDraftEditorProps = Pick<
  PluginEditorProps,
  'handleKeyCommand' | 'keyBindingFn' | 'editorState' | 'placeholder' | 'plugins' | 'onFocus'
> &
  TErrorProps & {
    onChange: (state: EditorState) => void

    focusOnMount?: boolean
    classes?: Partial<Record<TDraftEditorClasses, string>>
    helperText?: React.ReactNode
    startAdornment?: React.ReactNode
    endAdornment?: React.ReactNode
    valueLengthMax?: number
    showMaxLength?: boolean
    disabled?: boolean
    withEmoji?: boolean
    showEmoji?: boolean
    showFocus?: boolean
    readOnly?: boolean
    setShowEmoji?: Dispatch<SetStateAction<boolean>>
  }

export type TDraftEditorClasses =
  | 'editorWrapper'
  | 'editorEndAdornment'
  | 'emojiIcon'
  | 'editorStartAdornment'
  | 'editorContainer'

export const DraftEditor = ({
  editorState,
  onChange,
  keyBindingFn,
  handleKeyCommand,
  helperText,
  startAdornment,
  endAdornment,
  valueLengthMax,
  placeholder,
  classes,
  error,
  invalid,
  readOnly,
  onFocus,
  setShowEmoji,
  plugins = [],
  focusOnMount = true,
  showMaxLength = true,
  disabled = false,
  withEmoji = true,
  showFocus = false,
  showEmoji = false
}: TDraftEditorProps) => {
  const editorRef = useRef<any>(null)

  const { isDesktop } = useAdaptiveLayout()

  const { t } = useTranslation()

  const [emojiVisible, setEmojiVisible] = useState<boolean>(showEmoji)

  const isEditorFocused = editorRef.current?.editor.editor === document.activeElement
  const editorStateLength = editorState.getCurrentContent().getPlainText().length

  const handleToggleEmoji = useCallback(
    (visible: boolean) => {
      setShowEmoji ? setShowEmoji(visible) : setEmojiVisible(visible)
    },
    [setShowEmoji]
  )

  useEffect(() => {
    let timeout: NodeJS.Timeout

    if (focusOnMount) {
      // without setTimeout emoji will be rendered as native ones
      timeout = setTimeout(() => {
        editorRef.current?.focus()
      }, 4)
    }

    return () => {
      clearTimeout(timeout)
    }
  }, [focusOnMount])

  useEffect(() => {
    setEmojiVisible(showEmoji)
  }, [handleToggleEmoji, showEmoji])

  const onEmojiIconClick = () => {
    handleToggleEmoji(!emojiVisible)
  }

  const onEmojiOutsideClick = () => {
    handleToggleEmoji(false)
  }

  const focusOnEditor = () => {
    editorRef.current?.focus()
  }

  const handleChange = (state: EditorState) => {
    const contentState = state.getCurrentContent()

    if (contentState.getPlainText().length > MAX_DRAFT_LENGTH) {
      onChange(EditorState.undo(state))

      return
    }

    onChange(state)
  }

  const getLengthOfSelectedText = () => {
    const currentSelection = editorState.getSelection()
    const isCollapsed = currentSelection.isCollapsed()

    let length = 0

    if (!isCollapsed) {
      const currentContent = editorState.getCurrentContent()
      const startKey = currentSelection.getStartKey()
      const endKey = currentSelection.getEndKey()
      const startBlock = currentContent.getBlockForKey(startKey)
      const isStartAndEndBlockAreTheSame = startKey === endKey
      const startBlockTextLength = startBlock.getLength()
      const startSelectedTextLength = startBlockTextLength - currentSelection.getStartOffset()
      const endSelectedTextLength = currentSelection.getEndOffset()
      const keyAfterEnd = currentContent.getKeyAfter(endKey)

      if (isStartAndEndBlockAreTheSame) {
        length += currentSelection.getEndOffset() - currentSelection.getStartOffset()
      } else {
        let currentKey = startKey

        while (currentKey && currentKey !== keyAfterEnd) {
          if (currentKey === startKey) {
            length += startSelectedTextLength + 1
          } else if (currentKey === endKey) {
            length += endSelectedTextLength
          } else {
            length += currentContent.getBlockForKey(currentKey).getLength() + 1
          }

          currentKey = currentContent.getKeyAfter(currentKey)
        }
      }
    }

    return length
  }

  const handleBeforeInput = (): DraftHandleValue => {
    const currentContent = editorState.getCurrentContent()
    const currentContentLength = currentContent.getPlainText().length
    const selectedTextLength = getLengthOfSelectedText()

    if (currentContentLength - selectedTextLength > MAX_DRAFT_LENGTH - 1) {
      return 'handled'
    }

    return 'not-handled'
  }

  const removeSelection = () => {
    const selection = editorState.getSelection()
    const startKey = selection.getStartKey()
    const startOffset = selection.getStartOffset()
    const endKey = selection.getEndKey()
    const endOffset = selection.getEndOffset()

    if (startKey !== endKey || startOffset !== endOffset) {
      const newContent = Modifier.removeRange(editorState.getCurrentContent(), selection, 'forward')
      const tempEditorState = EditorState.push(editorState, newContent, 'remove-range')

      onChange(tempEditorState)

      return tempEditorState
    }

    return editorState
  }

  const addPastedContent = (input: string, state: EditorState) => {
    const inputLength = state.getCurrentContent().getPlainText().length
    const remainingLength = MAX_DRAFT_LENGTH - inputLength

    const newContent = Modifier.insertText(
      state.getCurrentContent(),
      state.getSelection(),
      input.slice(0, remainingLength)
    )

    onChange(EditorState.push(state, newContent, 'insert-fragment'))
  }

  const handlePastedText = (pastedText: string): DraftHandleValue => {
    const currentContent = editorState.getCurrentContent()
    const currentContentLength = currentContent.getPlainText().length
    const selectedTextLength = getLengthOfSelectedText()

    if (currentContentLength + pastedText.length - selectedTextLength > MAX_DRAFT_LENGTH) {
      const selection = editorState.getSelection()
      const isCollapsed = selection.isCollapsed()
      const tempEditorState = !isCollapsed ? removeSelection() : editorState

      addPastedContent(pastedText, tempEditorState)

      return 'handled'
    }

    return 'not-handled'
  }

  const handleEmoji = (emoji: string) => {
    if (!editorState) {
      throw new Error('Emoji-mart plugin: handle emoji fired on unbinded instace.')
    }

    const newEditorState = addEmoji(editorState, emoji)

    if (!onChange) {
      throw new Error('Emoji-mart plugin: handle emoji fired on unbinded instace.')
    }

    onChange(newEditorState)
  }

  const defaultReadOnly = readOnly ?? disabled

  return (
    <div
      className={cls(
        styles.editorContainer,
        disabled && styles.disabled,
        showFocus && isEditorFocused && styles.focused,
        invalid && styles.invalid,
        classes?.editorContainer
      )}
    >
      <div className={cls(styles.editorWrapper, classes?.editorWrapper)} onClick={focusOnEditor}>
        {!defaultReadOnly && (
          <div
            className={cls(
              styles.editorStartAdornment,

              classes?.editorStartAdornment
            )}
          >
            {startAdornment}
          </div>
        )}

        <Editor
          editorState={editorState}
          onChange={handleChange}
          plugins={[emojiPlugin, ...plugins]}
          keyBindingFn={keyBindingFn}
          handleKeyCommand={handleKeyCommand}
          handlePastedText={handlePastedText}
          handleBeforeInput={handleBeforeInput}
          placeholder={placeholder}
          ref={editorRef}
          readOnly={defaultReadOnly}
          autoCapitalize="off"
          autoComplete="off"
          autoCorrect="off"
          spellCheck={false}
          onFocus={onFocus}
        />

        <div className={cls(styles.editorEndAdornment, classes?.editorEndAdornment)}>
          {withEmoji && (
            <IconButton
              iconComponent={<EmojiIcon />}
              toolTip={t('common.draftEditor.emojiIconTooltip')}
              iconSize={EIconSize.MD}
              onClick={onEmojiIconClick}
              classes={{ root: styles.emojiIcon }}
            />
          )}
          {endAdornment}
        </div>
      </div>

      {!defaultReadOnly && (
        <>
          {emojiVisible && (
            <ClickAwayListener onOutsideClick={onEmojiOutsideClick}>
              <div className={styles.emojiWindow}>
                <EmojiPicker onSelect={handleEmoji} />
              </div>
            </ClickAwayListener>
          )}

          {isDesktop && (
            <div
              className={cls({
                [styles.editorHelperText]: true,
                [styles.editorHelperTextHidden]: !isEditorFocused
              })}
            >
              {helperText}
            </div>
          )}

          <HelperText>
            <Row gutter={[20, 0]} wrap={false}>
              <Col flex="auto">
                <ErrorHelper error={error} invalid={invalid} />
              </Col>
              {showMaxLength && valueLengthMax && (
                <Col flex="auto">
                  <LengthCounter currentLength={editorStateLength} maxLength={valueLengthMax} />
                </Col>
              )}
            </Row>
          </HelperText>
        </>
      )}
    </div>
  )
}
