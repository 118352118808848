import validator from 'validator'
import { isAfter, isBefore, setDate, setMonth, setYear } from 'date-fns'

import { FILE_ESCAPE_SYMBOLS } from 'globalConstants'
import { TRangePickerValue } from 'App/components'

import { formatLongDateTime } from '../formats'
import i18n from '../../i18n'

import { MAX_PASSWORD_LENGTH, MIN_PASSWORD_LENGTH } from './constants'

export type TValidatorResponse = string | null

class Validation {
  public rules = {
    email: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
    personName: /^[\p{L}'\-\s]+$/u,
    digits: /^\d+$/,
    notDigits: /[^\d]/g,
    price: /^(?:[1-9][0-9]*|0)$/,
    attachmentLink:
      // eslint-disable-next-line no-useless-escape
      /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/,
    password: new RegExp(
      `^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[\\;\\(\\)\\"\\@\\#\\$\\%\\^\\&\\*\\-\\_\\!\\+\\=\\[\\]\\{\\}\\|\\:\\'\\,\\.\\?\\/\`\\~])[A-Za-z\\d\\;\\(\\)\\"\\@\\#\\$\\%\\^\\&\\*\\-\\_\\!\\+\\=\\[\\]\\{\\}\\|\\:\\'\\,\\.\\?\\/\`\\~]{${MIN_PASSWORD_LENGTH},${MAX_PASSWORD_LENGTH}}$`
    ),
    companyName: /^[0-9a-zA-Z'"’“”-]+(\s[0-9a-zA-Z'"’“”-]+)*$/,
    onlySpaces: /^\s+$/
  }

  public required =
    (errMessage: string = i18n.t('validationErrors.required', { ns: 'errors' })) =>
    (value: any): TValidatorResponse => {
      if (Array.isArray(value)) {
        return value.length && value.every(Boolean) ? null : errMessage
      }

      let isOnlySpace = false

      if (typeof value === 'string') {
        isOnlySpace = this.rules.onlySpaces.test(value)
      }

      if (value === '' || isOnlySpace) {
        return isOnlySpace ? i18n.t('validationErrors.onlySpaces', { ns: 'errors' }) : errMessage
      }

      return value || value === 0 ? null : errMessage
    }

  public isNumber =
    (errMessage?: string) =>
    (value: number): TValidatorResponse => {
      if (value === undefined || !isNaN(value)) {
        return null
      }

      return errMessage ? errMessage : i18n.t('validationErrors.isNumber', { ns: 'errors' })
    }

  public isZip =
    (errMessage?: string) =>
    (value: string): TValidatorResponse => {
      if (validator.isPostalCode(value, 'any')) {
        return null
      }

      return errMessage ? errMessage : i18n.t('validationErrors.zip', { ns: 'errors' })
    }

  public isUrl =
    (errMessage: string = i18n.t('validationErrors.invalidLink', { ns: 'errors' })) =>
    (value: string): TValidatorResponse => {
      if (value && !validator.isURL(value)) {
        return errMessage
      }

      return null
    }

  public isEmail =
    (errMessage: string = i18n.t('validationErrors.email', { ns: 'errors' })) =>
    (value: string): TValidatorResponse => {
      if (this.rules.email.test(value)) {
        return null
      }

      return errMessage
    }

  public isPassword =
    (errMessage: string = i18n.t('validationErrors.password', { ns: 'errors' })) =>
    (value: string): TValidatorResponse => {
      if (this.rules.password.test(value)) {
        return null
      }

      return errMessage
    }

  public isPersonName =
    (
      type: 'name' | 'lastName',
      errMessage = i18n.t(`validationErrors.${type}`, { ns: 'errors' })
    ) =>
    (value: string): TValidatorResponse => {
      if (this.rules.personName.test(value)) {
        return null
      }

      return errMessage
    }

  public isPasswordConfirm =
    (errMessage: string = i18n.t('validationErrors.confirmPassword', { ns: 'errors' })) =>
    (value: string, values: Record<string, unknown>): TValidatorResponse => {
      if (values.password !== value) {
        return errMessage
      }

      return null
    }

  public minLength =
    (min: number, message: string = i18n.t('validationErrors.min', { value: min, ns: 'errors' })) =>
    (value: string | []): TValidatorResponse =>
      value == null || value === '' || value.length >= min ? null : message

  public maxLength =
    (max: number, message?: string) =>
    (value: string | any[]): TValidatorResponse =>
      value == null || value === '' || value.length <= max
        ? null
        : message || i18n.t('validationErrors.max', { value: max, ns: 'errors' })

  public min =
    (min: number, message?: string) =>
    (value: number): TValidatorResponse =>
      value === undefined || value >= min
        ? null
        : message || i18n.t('validationErrors.min', { value: min, ns: 'errors' })

  public max =
    (max: number, message?: string) =>
    (value?: number): TValidatorResponse =>
      value === undefined || value <= max
        ? null
        : message || i18n.t('validationErrors.max', { value: max, ns: 'errors' })

  public natural =
    (message?: string) =>
    (value?: number): TValidatorResponse =>
      value === undefined || (value >= 0 && Math.floor(value) === +value)
        ? null
        : message || i18n.t('validationErrors.natural', { ns: 'errors' })

  public minDate =
    (min: number | string | Date) =>
    (value: string | number | Date | string[] | null): string | null => {
      const date = Array.isArray(value) ? value[0] : value

      return !date || isAfter(new Date(date), new Date(min))
        ? null
        : i18n.t('validationErrors.minDate', { date: formatLongDateTime(min), ns: 'errors' })
    }

  public maxDate = (max: number | string | Date) => (value: string | number | Date | null) =>
    !value || isBefore(new Date(value), new Date(max))
      ? null
      : i18n.t('validationErrors.maxDate', { date: formatLongDateTime(max), ns: 'errors' })

  public fileExtensions = (extensions: string[]) => (value: File[]) =>
    value == null ||
    value.every((item) =>
      extensions.includes(((item && item.name.split('.').pop()) || '').toLowerCase())
    )
      ? null
      : i18n.t('validationErrors.invalidExtension', {
          extensions: extensions.join(', '),
          ns: 'errors'
        })

  public fileEscapeSymbols =
    (errMessage: string = i18n.t('validationErrors.fileEscapeSymbols', { ns: 'errors' })) =>
    (value: string): TValidatorResponse =>
      value.split('').some((char) => FILE_ESCAPE_SYMBOLS.includes(char)) ? errMessage : null

  public digits = (errMessage?: string) => (value: string | number) =>
    value != null && value !== '' && !this.rules.digits.test(String(value))
      ? errMessage || i18n.t('validationErrors.onlyDigits', { ns: 'errors' })
      : null

  public price = (errMessage?: string) => (value: string | number) =>
    value != null && value !== '' && !this.rules.price.test(String(value))
      ? errMessage || i18n.t('validationErrors.price', { ns: 'errors' })
      : null

  public onlySpaces = (errMessage?: string) => (value: string) =>
    this.rules.onlySpaces.test(value)
      ? errMessage || i18n.t('validationErrors.onlySpaces', { ns: 'errors' })
      : null

  public companyName = (errMessage?: string) => (value: string) =>
    this.rules.companyName.test(value?.trim())
      ? null
      : errMessage || i18n.t('validationErrors.companyName', { ns: 'errors' })

  public composeValidators =
    (...validators: any[]) =>
    (value: any, values: any) =>
      validators.reduce(
        (error, callback) => error || (typeof callback === 'function' && callback(value, values)),
        null
      )
  public composeValidatorsForArray =
    (...validators: any[]) =>
    (value: any[], values: Record<string, any>, getValue?: (value: any) => string | number) => {
      const res = validators.reduce((errors, callback) => {
        const error = value.reduce(
          (resError, item) =>
            resError ||
            (typeof callback === 'function' && callback(getValue ? getValue(item) : item, values)),
          null
        )

        if (error) {
          return [...errors, error]
        }

        return errors
      }, [])

      return res.length > 0 ? res.join('\n') : null
    }

  public timeRange = (errMessage?: string) => (value: TRangePickerValue) => {
    if (value && value[0] && value[1] && value[0] > value[1]) {
      return errMessage || i18n.t('validationErrors.invalidTimeRange', { ns: 'errors' })
    }

    return undefined
  }

  public timeRangeMinDate = (minDate?: Date, errMessage?: string) => (value: TRangePickerValue) => {
    if (value) {
      if (value[0] && value[1]) {
        const startTime = minDate
          ? setYear(
              setMonth(setDate(value[0], minDate.getDate()), minDate.getMonth()),
              minDate.getFullYear()
            )
          : value[0]

        if (startTime.getTime() < Date.now()) {
          return errMessage || i18n.t('validationErrors.invalidStartTime', { ns: 'errors' })
        }
      }

      return undefined
    }

    return undefined
  }
}

export const validation = new Validation()
