import { AccountTypeNames } from '@medentee/enums'
import { ReactNode } from 'react'

import { EAvatarSize } from 'enums'
import { AccountName, Avatar, TAccountNameProps, TAvatarProps } from 'App/components/common'

import styles from './UnityHubInfoOwnerAccount.module.scss'
import { UnityHubViewCommunityButton } from './UnityHubViewCommunityButton'

export type TUnityHubInfoOwnerAccountProps = Pick<
  TAvatarProps,
  | 'firstName'
  | 'lastName'
  | 'displayUserName'
  | 'userId'
  | 'isCommunity'
  | 'isEventOrganizer'
  | 'eventId'
> &
  Pick<TAccountNameProps, 'shouldRedirectToUserProfile'> & {
    title?: ReactNode
    label?: ReactNode
    communityId?: string
  }

export const UnityHubInfoOwnerAccount = ({
  displayUserName,
  firstName,
  lastName,
  userId,
  isCommunity,
  isEventOrganizer,
  shouldRedirectToUserProfile,
  label,
  title,
  eventId,
  communityId
}: TUnityHubInfoOwnerAccountProps) => (
  <div className={styles.root}>
    {!!title && <div className={styles.title}>{title}</div>}

    <div className={styles.content}>
      <Avatar
        firstName={firstName}
        lastName={lastName}
        displayUserName={displayUserName}
        type={AccountTypeNames.BUSINESS}
        size={EAvatarSize.XS}
        userId={userId}
        hasTooltip={false}
        isCommunity={isCommunity}
        isEventOrganizer={isEventOrganizer}
        eventId={eventId}
      />

      <div className={styles.name}>
        {!!label && <span className={styles.label}>{label}</span>}
        <AccountName
          displayUserName={displayUserName}
          type={AccountTypeNames.BUSINESS}
          userId={userId}
          size={EAvatarSize.XS}
          hideAccountType
          hideIcon
          shouldRedirectToUserProfile={shouldRedirectToUserProfile}
        />
      </div>
    </div>
    {communityId && <UnityHubViewCommunityButton communityId={communityId} />}
  </div>
)
