import { ReactNode, useCallback, useMemo, useState, useRef, useEffect } from 'react'
import { SwiperSlide } from 'swiper/react'
import { Swiper as ISwiper } from 'swiper/types'

import { ARCHIVE_WHITELIST, MODELS_WHITELIST } from 'globalConstants'
import { getExtensionWithoutDot } from 'utils/files'
import { useAdaptiveLayout, useRefValue } from 'App/hooks'
import { TFileViewerProps, TImageViewerProps } from 'App/components'
import { EExtendableCardType, useAppSelector } from 'store'

import { SlideViewPlug, TSlideViewPlugProps } from '../SlideViewPlug'
import { Thumbnail } from '../Thumbnail'

export type TUseSwiperOptions = Pick<TSlideViewPlugProps, 'preview' | 'onPreviewClick'> & {
  slides: TFileViewerProps[]
  type: EExtendableCardType
  initialSlideId: string

  isThumbnails?: boolean
  getPlaceholder?: (args: TFileViewerProps) => ReactNode
  thumbnailOptions?: {
    showSlideIndex?: boolean
  }
  download?: boolean
  imageClasses?: TImageViewerProps['classes']
}

export const useSwiper = ({
  slides,
  initialSlideId,
  isThumbnails,
  type,
  imageClasses,
  preview,
  getPlaceholder,
  onPreviewClick,
  thumbnailOptions = { showSlideIndex: false }
}: TUseSwiperOptions) => {
  const { isDesktop } = useAdaptiveLayout()

  const rootRef = useRef<HTMLDivElement>(null)

  const [currentSlideId, setCurrentSlideId] = useState<string>(initialSlideId)

  const filteredSlides = useAppSelector((state) =>
    slides.filter(({ id }) => state.videoStreaming.list[id]?.videoMetadata?.quality !== null)
  )

  const elements = useMemo(
    () =>
      filteredSlides.map((item, index) => {
        const shouldDisabledSwipe =
          [...MODELS_WHITELIST, ...ARCHIVE_WHITELIST].includes(
            getExtensionWithoutDot(item.extension)
          ) || isDesktop

        return (
          <SwiperSlide
            key={item.id}
            virtualIndex={index}
            className={shouldDisabledSwipe ? 'swiper-no-swiping' : ''}
          >
            <SlideViewPlug
              rootRef={rootRef}
              type={type}
              currentSlideId={currentSlideId}
              placeholder={getPlaceholder ? getPlaceholder(item) : undefined}
              imageClasses={imageClasses}
              preview={preview}
              onPreviewClick={onPreviewClick}
              {...item}
            />
          </SwiperSlide>
        )
      }),
    [
      filteredSlides,
      isDesktop,
      type,
      currentSlideId,
      getPlaceholder,
      imageClasses,
      preview,
      onPreviewClick
    ]
  )

  const { getValue: getCurrentSlideId } = useRefValue(currentSlideId)
  const currentSlideIndex = filteredSlides.findIndex(({ id }) => id === currentSlideId)
  const currentSlide = filteredSlides[currentSlideIndex] ?? {}

  const onSlideChange = useCallback(
    (swiper: ISwiper) => {
      setCurrentSlideId(filteredSlides[swiper.activeIndex].id)
    },
    [setCurrentSlideId, filteredSlides]
  )

  const thumbnails = useMemo(() => {
    if (!isThumbnails) {
      return null
    }

    return filteredSlides.map(({ extension, id, thumbnailUrl, fileName = '' }, index) => (
      <SwiperSlide key={id} virtualIndex={index}>
        <Thumbnail
          fileName={fileName}
          extension={extension}
          thumbnailUrl={thumbnailUrl}
          slideIndex={thumbnailOptions.showSlideIndex ? index : undefined}
        />
      </SwiperSlide>
    ))
  }, [isThumbnails, thumbnailOptions, filteredSlides])

  useEffect(() => {
    // if new slides do not contain current slide set new currentSlideId
    if (slides.length && !slides.find((item) => item.id === getCurrentSlideId())) {
      setCurrentSlideId(slides[0].id)
    }
  }, [slides, setCurrentSlideId, getCurrentSlideId])

  return {
    elements,
    thumbnails,
    currentSlideIndex,
    currentSlide,
    rootRef,
    onSlideChange,
    setCurrentSlideId
  }
}
