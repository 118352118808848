import { Col, Row } from 'antd'
import { Field, useFormState } from 'react-final-form'
import { AccountTypeNames, EventRepresentativeTypeEnum } from '@medentee/enums'
import { Trans, useTranslation } from 'react-i18next'

import { TEventRepresantatives } from 'interfaces/api/events'
import {
  UnityHubSettingsRadio,
  UnityHubSettingsRadioGroup
} from 'App/components/UnityHub/UnityHubSettings/UnityHubSettingsRadio'
import { UnityHubSettingsTitle } from 'App/components/UnityHub/UnityHubSettings/UnityHubSettingsTitle'
import { UnityHubSettingsDescription } from 'App/components/UnityHub/UnityHubSettings/UnityHubSettingsDescription'
import { MAX_LENGTH_100, MIN_LENGTH_1 } from 'globalConstants'
import { hideModalAction, showModalAction, useAppDispatch } from 'store'
import { EModalComponents } from 'App/containers'

import { validation } from '../../../../../utils'
import { Switch, TextField, TextFieldSkeleton, UploadAvatar } from '../../../../components'
import { TOrganizerSettingsFormValues } from '../OrganizerSettings'

import styles from './OrganizerProfile.module.scss'

export type TOrganizerProfileProps = {
  loading: boolean
  representatives: TEventRepresantatives
  onAvatarChange: () => void
}

export const OrganizerProfile = ({
  loading,
  representatives,
  onAvatarChange
}: TOrganizerProfileProps) => {
  const { values } = useFormState<TOrganizerSettingsFormValues>()

  const { t } = useTranslation()

  const dispatch = useAppDispatch()

  const handleFileUpload = (value: File, onChange: (file: File) => void) => {
    dispatch(
      showModalAction({
        modalType: EModalComponents.UPLOAD_IMAGE_DIALOG,
        modalTitle: t('modal.uploadImage.title_userAvatar'),
        modalProps: {
          imageSrc: URL.createObjectURL(value),
          onCrop: (newFile) => {
            onAvatarChange()
            dispatch(hideModalAction())
            onChange(newFile)
          }
        }
      })
    )
  }

  return (
    <Col xs={24}>
      <Row gutter={[0, 24]}>
        <Col xs={24}>
          <Row gutter={[0, 12]}>
            <Col xs={24}>
              <UnityHubSettingsTitle title={t('events.organizerSettings.organizerProfileTitle')} />
            </Col>
            <Col xs={24}>
              <UnityHubSettingsDescription
                description={t('events.organizerSettings.representativesListTitle')}
              />
            </Col>
            <Col xs={24}>
              <TextFieldSkeleton loading={loading}>
                <Field<TOrganizerSettingsFormValues['representativeType']>
                  name="representativeType"
                  validate={validation.required()}
                  type="radio"
                >
                  {({ input: { name, onChange } }) => (
                    <UnityHubSettingsRadioGroup
                      name={name}
                      value={values.representativeType}
                      onChange={onChange}
                    >
                      <UnityHubSettingsRadio value={EventRepresentativeTypeEnum.BUSINESS_ACCOUNT}>
                        <Trans
                          t={t}
                          i18nKey="events.organizerSettings.representative_business"
                          components={{
                            name: (
                              <strong>
                                {representatives?.businessAccounts[0]?.displayUserName}
                              </strong>
                            )
                          }}
                        />
                      </UnityHubSettingsRadio>
                      <UnityHubSettingsRadio value={EventRepresentativeTypeEnum.ORGANIZATION}>
                        <Trans
                          t={t}
                          i18nKey="events.organizerSettings.representative_organization"
                          components={{
                            name: (
                              <strong>{representatives?.organizations[0]?.displayUserName}</strong>
                            )
                          }}
                        />
                      </UnityHubSettingsRadio>
                      {!!representatives?.communities.length && (
                        <UnityHubSettingsRadio value={EventRepresentativeTypeEnum.COMMUNITY}>
                          <Trans
                            t={t}
                            i18nKey="events.organizerSettings.representative_community"
                            components={{
                              name: <strong>{representatives?.communities[0]?.title}</strong>
                            }}
                          />
                        </UnityHubSettingsRadio>
                      )}
                    </UnityHubSettingsRadioGroup>
                  )}
                </Field>
              </TextFieldSkeleton>
            </Col>
          </Row>
        </Col>
        <Col xs={24}>
          <TextFieldSkeleton loading={loading}>
            <Field<TOrganizerSettingsFormValues['differentOrganizer']> name="differentOrganizer">
              {({ input }) => (
                <Switch
                  {...input}
                  checked={input.value}
                  label={t('events.organizerSettings.differentOrganizerSwitch')}
                  classes={{ root: styles.switch }}
                />
              )}
            </Field>
          </TextFieldSkeleton>
        </Col>
        {!!values.differentOrganizer && (
          <Col xs={24}>
            <Row gutter={[0, 12]}>
              <Col xs={24}>
                <UnityHubSettingsDescription
                  description={t('events.organizerSettings.differentOrganizerDescription')}
                />
              </Col>
              <Col xs={24}>
                <Row gutter={[24, 12]}>
                  <Col flex="none">
                    <Field<
                      TOrganizerSettingsFormValues['differentOrganizerAvatar']
                    > name="differentOrganizerAvatar">
                      {({ input }) => (
                        <UploadAvatar
                          file={input.value || null}
                          showEditPreview={!values.differentOrganizerName || !input.value}
                          displayUserName={values.differentOrganizerName ?? ''}
                          classes={{
                            remove: styles.removePhotoButton,
                            removeIcon: styles.removePhotoIcon
                          }}
                          type={AccountTypeNames.BUSINESS}
                          onFileUpload={(file) => {
                            handleFileUpload(file, input.onChange)
                          }}
                          onRemoveAvatar={() => {
                            onAvatarChange()
                            input.onChange(null)
                          }}
                        />
                      )}
                    </Field>
                  </Col>
                  <Col flex="auto">
                    <TextFieldSkeleton loading={loading}>
                      <Field<TOrganizerSettingsFormValues['differentOrganizerName']>
                        name="differentOrganizerName"
                        validate={validation.composeValidators(
                          values.differentOrganizer ? validation.required() : null,
                          validation.onlySpaces(),
                          validation.minLength(MIN_LENGTH_1),
                          validation.maxLength(MAX_LENGTH_100)
                        )}
                      >
                        {({ input, meta }) => (
                          <TextField
                            {...input}
                            valueLengthMax={MAX_LENGTH_100}
                            invalid={meta.touched && meta.invalid}
                            error={meta.error}
                            classes={{ wrapper: styles.name }}
                            topLabel={t('events.organizerSettings.differentOrganizerName')}
                          />
                        )}
                      </Field>
                    </TextFieldSkeleton>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        )}
      </Row>
    </Col>
  )
}
