import { ReactNode, RefObject, useCallback } from 'react'
import { FileHistorySourcesEnum } from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import { IFilesEntity } from 'interfaces'
import {
  MODELS_WHITELIST,
  DOCUMENTS_WHITELIST,
  IMAGE_WHITELIST,
  AUDIO_WHITELIST,
  VIDEO_WHITELIST,
  SPREAD_WHITELIST,
  PRESENTATION_WHITELIST
} from 'globalConstants'
import {
  ModelViewer,
  PDFViewer,
  ImageViewer,
  AudioViewer,
  VideoViewer,
  DocumentViewer,
  TextViewer,
  TImageViewerProps
} from 'App/components'
import { getExtensionWithoutDot } from 'utils/files'

import styles from './FileViewer.module.scss'

export type TFileViewerProps = PartialBy<
  Pick<IFilesEntity, 'id' | 'extension' | 'mimeType' | 'thumbnailUrl' | 'fileName'>,
  'thumbnailUrl' | 'fileName' | 'mimeType'
> & {
  fileSrc?: string
  imageClasses?: TImageViewerProps['classes']
  source?: FileHistorySourcesEnum
  originalEntityId?: string
  placeholder?: ReactNode
  slideRef?: RefObject<HTMLDivElement>
  preload?: boolean
  preview?: boolean
  isActive?: boolean
  onPreviewClick?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void
}

const UNSUPPORTED_EXTENSIONS = [
  'tiff',
  'tif',
  'avchd',
  'aiff',
  'pcm',
  'flac',
  'alac',
  'wma',
  'aac',
  'dicom',
  'dcm',
  'pages',
  'numbers',
  'pptm',
  'potx',
  'key',
  'pez'
]

export const FileViewer = ({
  id,
  extension,
  source,
  originalEntityId,
  mimeType,
  imageClasses,
  slideRef,
  preload,
  thumbnailUrl,
  fileName,
  isActive,
  placeholder,
  fileSrc,
  onPreviewClick,
  preview = false
}: TFileViewerProps) => {
  const { t } = useTranslation()

  const fileExtension = getExtensionWithoutDot(extension)

  const getPlaceholder = useCallback(
    () => (
      <div className={styles.root}>{placeholder ?? t('common.fileViewer.unableToProcess')}</div>
    ),
    [placeholder, t]
  )

  const getViewer = () => {
    switch (true) {
      case UNSUPPORTED_EXTENSIONS.includes(fileExtension): {
        return getPlaceholder()
      }

      case MODELS_WHITELIST.includes(fileExtension):
      case fileExtension === 'zip': {
        return (
          <ModelViewer
            extension={fileExtension}
            id={id}
            source={source}
            originalEntityId={originalEntityId}
          />
        )
      }

      case fileExtension === 'pdf': {
        return <PDFViewer id={id} source={source} originalEntityId={originalEntityId} />
      }

      case fileExtension === 'txt': {
        return <TextViewer id={id} source={source} originalEntityId={originalEntityId} />
      }

      case DOCUMENTS_WHITELIST.includes(fileExtension):
      case SPREAD_WHITELIST.includes(fileExtension):
      case PRESENTATION_WHITELIST.includes(fileExtension): {
        return <DocumentViewer id={id} source={source} originalEntityId={originalEntityId} />
      }

      case IMAGE_WHITELIST.includes(fileExtension): {
        return (
          <ImageViewer
            id={id}
            source={source}
            originalEntityId={originalEntityId}
            classes={imageClasses}
            fileSrc={fileSrc}
          />
        )
      }

      case VIDEO_WHITELIST.includes(fileExtension): {
        return (
          <VideoViewer
            id={id}
            isActive={isActive}
            preview={preview}
            thumbnailUrl={thumbnailUrl}
            fileName={fileName}
            onPreviewClick={onPreviewClick}
          />
        )
      }
      case AUDIO_WHITELIST.includes(fileExtension): {
        return (
          <AudioViewer
            id={id}
            source={source}
            originalEntityId={originalEntityId}
            mimeType={mimeType}
          />
        )
      }

      default:
        return getPlaceholder()
    }
  }

  return (
    <div className={styles.content} ref={slideRef}>
      {preload ? getViewer() : null}
    </div>
  )
}
