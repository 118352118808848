import { ChangeEvent, ReactNode, useCallback, useMemo, useRef } from 'react'
import { Skeleton } from 'antd'
import { toast } from 'react-toastify'
import cls from 'classnames'
import { useTranslation } from 'react-i18next'

import { hideModalAction, showModalAction, useAppDispatch } from 'store'
import { convertStorageUnitsToBytes } from 'utils'
import { toastDefaultOptions } from 'globalConstants'
import { EStorageUnitsEnum } from 'enums'
import { ReactComponent as TrashIcon } from 'assets/icons/Trash.svg'
import { ReactComponent as CameraPlusIcon } from 'assets/icons/CameraPlus.svg'
import { useAdaptiveLayout, useToggle } from 'App/hooks'

import { Image } from '../Image'
import { ContextMenu } from '../ContextMenu'
import { TMenuItemProps } from '../MenuList'
import { EModalComponents } from '../../../containers'

import styles from './UploadCover.module.scss'

export type TUploadCoverProps = PropsWithClasses<
  'skeleton' | 'root' | 'remove' | 'removeIcon' | 'uploadIcon',
  {
    placeholder?: ReactNode
    maxFileSize?: number
    file?: File | null | string
    loading?: boolean
    uploadButtonLabel?: string
    removeButtonLabel?: string
    removeModalTitle?: string
    removeModalContent?: ReactNode
    removeModalButtonContent?: ReactNode
    onCoverClick?: () => void
    onRemove?: () => void
    onFileUpload?: (file: File) => void
  }
>

const ACCEPTED_IMAGE_TYPES = 'image/jpeg, image/jpg, image/png'

export const UploadCover = ({
  file,
  loading,
  classes,
  removeModalContent,
  removeModalButtonContent,
  maxFileSize = 5,
  placeholder = (
    <div className={styles.placeholder}>
      <div className={styles.circle1} />
      <div className={styles.circle2} />
      <div className={styles.circle3} />
    </div>
  ),
  onFileUpload,
  onRemove,
  onCoverClick,
  ...rest
}: TUploadCoverProps) => {
  const dispatch = useAppDispatch()

  const { isMobile } = useAdaptiveLayout()

  const { value: hasFile, toggleOff, toggleOn } = useToggle(false)

  const inputRef = useRef<HTMLInputElement>(null)

  const { t } = useTranslation()

  const uploadButtonLabel = rest.uploadButtonLabel ?? t('common.uploadCover.uploadButton')
  const removeButtonLabel = rest.removeButtonLabel ?? t('common.uploadCover.removeButton')
  const removeModalTitle = rest.removeModalTitle ?? t('modal.removeImageConfirm.title')

  const showRemoveButton = hasFile && onRemove

  const src = useMemo(() => {
    if (file instanceof File) {
      return URL.createObjectURL(file)
    }

    return file
  }, [file])

  const handleClick = useCallback(() => {
    inputRef?.current?.click()
  }, [])

  const handleUpload = useCallback(
    (nextFile: File) => {
      if (onFileUpload) {
        onFileUpload(nextFile)
      }
    },
    [onFileUpload]
  )
  const handleRemove = useCallback(() => {
    dispatch(
      showModalAction({
        modalTitle: removeModalTitle,
        modalType: EModalComponents.DELETE_IMAGE_DIALOG,
        modalProps: {
          content: removeModalContent,
          buttonContent: removeModalButtonContent,
          onClick: () => {
            if (onRemove) {
              onRemove()
            }
            toggleOff()
            dispatch(hideModalAction())
          }
        }
      })
    )
  }, [
    dispatch,
    removeModalTitle,
    removeModalContent,
    toggleOff,
    removeModalButtonContent,
    onRemove
  ])

  const handleFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.currentTarget.files?.length) {
      return
    }

    const currentFile = event.currentTarget.files[0]

    if (currentFile.size > convertStorageUnitsToBytes(maxFileSize, EStorageUnitsEnum.MB)) {
      toast.error(
        t('common.uploadCover.maxSizeError', { maxSize: `${maxFileSize}MB` }),
        toastDefaultOptions
      )
    } else {
      handleUpload(currentFile)
    }

    if (inputRef && inputRef.current) {
      inputRef.current.value = ''
    }
  }
  const menuItems = useMemo<TMenuItemProps[]>(
    () => [
      {
        content: uploadButtonLabel,
        icon: <CameraPlusIcon />,
        onClick: handleClick
      },
      {
        content: removeButtonLabel,
        icon: <TrashIcon />,
        onClick: handleRemove,
        hidden: !hasFile
      }
    ],
    [hasFile, handleClick, handleRemove, removeButtonLabel, uploadButtonLabel]
  )

  return (
    <Skeleton
      avatar={true}
      active={true}
      title={false}
      paragraph={{ rows: 1 }}
      loading={loading}
      className={cls(styles.skeleton, classes?.skeleton)}
    >
      <div className={cls(styles.root, { [styles.withImage]: hasFile }, classes?.root)}>
        <input
          ref={inputRef}
          accept={ACCEPTED_IMAGE_TYPES}
          type="file"
          onChange={handleFileUpload}
          className={styles.hidden}
        />

        <div className={styles.wrapper}>
          <Image
            hidden={!file}
            src={src}
            alt="avatar"
            placeholder={placeholder}
            onLoad={toggleOn}
            onError={toggleOff}
            className={styles.image}
            onClick={onCoverClick}
          />
        </div>

        {onFileUpload && (
          <>
            {isMobile ? (
              <ContextMenu classes={{ icon: styles.contextMenu }} menuItems={menuItems} />
            ) : (
              <div className={styles.buttons}>
                {
                  <button
                    className={cls(styles.controlButton, classes?.remove)}
                    onClick={handleClick}
                  >
                    <CameraPlusIcon className={cls(styles.icon, classes?.removeIcon)} />
                    {uploadButtonLabel}
                  </button>
                }
                {showRemoveButton && (
                  <button
                    className={cls(styles.controlButton, classes?.remove)}
                    onClick={handleRemove}
                  >
                    <TrashIcon className={cls(styles.icon, classes?.uploadIcon)} />
                    {removeButtonLabel}
                  </button>
                )}
              </div>
            )}
          </>
        )}
      </div>
    </Skeleton>
  )
}
