import { useCallback, useMemo, useState } from 'react'
import { MentionSuggestionsPubProps } from '@draft-js-plugins/mention/lib/MentionSuggestions/MentionSuggestions'
import { useTranslation } from 'react-i18next'

import { useQuery } from 'services/query'
import { TAccountSuggestion, TDepartmentSuggestion } from 'interfaces'
import { getOrganizationDepartments } from 'api/organizations'
import { getChatMembers } from 'api/chats'
import {
  TAccountMention,
  TDepartmentMention,
  TGeneralMention,
  TGroupHeader,
  TMention
} from 'utils/mentions'
import { formatAccountMentions, formatDepartmentMentions } from 'utils/chat'

const CHAT_ACCOUNT_SUGGESTIONS = 'chat_account_suggestions'
const CHAT_DEPARTMENT_SUGGESTIONS = 'chat_department_suggestions'

const CHAT_ACCOUNT_SUGGESTIONS_SHOW_BY = 7
const CHAT_DEPARTMENT_SUGGESTIONS_SHOW_BY = 2

type TUseChatFiledSuggestions = {
  chatId?: string | null
  organizationId?: string | null
  channelManagerId?: string
}

type TCreateMentionsListProps = {
  accountSuggestions?: TAccountSuggestion[]
  departmentSuggestions?: TDepartmentSuggestion[]
  channelManagerId?: string
}

const COMMON_MENTIONS_LIST: TGeneralMention[] = [
  {
    id: 'everyone',
    name: 'everyone',
    trigger: '@',
    variant: 'general'
  },
  {
    id: 'here',
    name: 'here',
    trigger: '@',
    variant: 'general'
  }
]

export const createMentionsList = ({
  channelManagerId,
  accountSuggestions = [],
  departmentSuggestions = []
}: TCreateMentionsListProps) => {
  const accountMentions = formatAccountMentions(accountSuggestions, channelManagerId)
  const departmentMentions = formatDepartmentMentions(departmentSuggestions)

  return [...accountMentions, ...departmentMentions, ...COMMON_MENTIONS_LIST]
}

export const useChatFiledSuggestions = ({
  chatId,
  organizationId,
  channelManagerId
}: TUseChatFiledSuggestions) => {
  const { t } = useTranslation()

  const [suggestionSearch, setSuggestionSearch] = useState('')
  const [enableSuggestions, setEnableSuggestions] = useState<boolean>(false)

  const { data: accountSuggestions = [] } = useQuery({
    queryKey: [CHAT_ACCOUNT_SUGGESTIONS, organizationId, suggestionSearch],
    queryFn: chatId
      ? () =>
          getChatMembers({
            chatId,
            search: suggestionSearch,
            showBy: CHAT_ACCOUNT_SUGGESTIONS_SHOW_BY
          })
      : undefined,
    select: (response) => formatAccountMentions(response?.results ?? [], channelManagerId),
    enabled: enableSuggestions && !!chatId
  })

  const { data: departmentsSuggestions = [] } = useQuery({
    queryKey: [CHAT_DEPARTMENT_SUGGESTIONS, chatId, suggestionSearch],
    queryFn: organizationId
      ? () =>
          getOrganizationDepartments({
            organizationId,
            search: suggestionSearch,
            showBy: CHAT_DEPARTMENT_SUGGESTIONS_SHOW_BY
          })
      : undefined,
    select: (response) => formatDepartmentMentions(response?.results ?? []),
    enabled: enableSuggestions && !!organizationId
  })

  const accountGroup = useMemo<(TAccountMention | TGroupHeader)[]>(
    () =>
      accountSuggestions.length
        ? [
            { name: t('common.mentions.membersLabel'), variant: 'groupHeader', trigger: '@' },
            ...accountSuggestions
          ]
        : [],
    [accountSuggestions, t]
  )

  const departmentGroup = useMemo<(TDepartmentMention | TGroupHeader)[]>(
    () =>
      departmentsSuggestions.length
        ? [
            { name: t('common.mentions.departmentsLabel'), variant: 'groupHeader', trigger: '@' },
            ...departmentsSuggestions
          ]
        : [],
    [departmentsSuggestions, t]
  )

  const suggestions = useMemo<TMention[]>(
    () => [
      ...COMMON_MENTIONS_LIST.filter(({ name }) => new RegExp(suggestionSearch, 'gm').test(name)),
      ...accountGroup,
      ...departmentGroup
    ],
    [accountGroup, departmentGroup, suggestionSearch]
  )

  const onSearchChange = useCallback<MentionSuggestionsPubProps['onSearchChange']>(({ value }) => {
    setSuggestionSearch(value)
    setEnableSuggestions(true)
  }, [])

  return {
    onSearchChange,
    suggestions
  }
}
