import {
  memo,
  ReactNode,
  RefObject,
  useEffect,
  useMemo,
  useRef,
  useState,
  useCallback,
  MouseEvent as ReactMouseEvent,
  TouchEvent as ReactTouchEvent
} from 'react'
import cls from 'classnames'
import { AccountTypeNames, ChatTypeEnum, MessageTypeEnum } from '@medentee/enums'
import {
  LongPressCallback,
  LongPressDetectEvents,
  LongPressResult,
  useLongPress
} from 'use-long-press'
import { useTranslation } from 'react-i18next'

import { EChatViewType, ESendMessageStatus } from 'enums'
import { formatShortDate } from 'utils'
import { NewDividerLine } from 'App/components'
import {
  ChatMessageAudioContainer,
  ChatMessageCallContainer,
  ChatMessageFileContainer,
  ChatMessageSystemContainer,
  ChatMessageTextContainer,
  ChatMessageMeetingContainer,
  ChatMessageDiscardedFilePermissionContainer,
  TChatMessageContainerViewProps
} from 'App/containers'
import { TAccount, TChatQuoteMessage } from 'store'
import { ChatMessagePinnedMessage } from 'App/containers/Chat/ChatMessagePinnedMessage'
import { useAdaptiveLayout, useOnScreen } from 'App/hooks'
import styleVariables from 'assets/style/_variables.module.scss'

import styles from './ChatMessageWrapper.module.scss'

export type TChatMessageWrapperBaseProps = {
  getMessage: (props: TChatMessageWrapperReturnProps) => ReactNode
}

export type TChatMessageWrapperProps = PartialBy<
  Omit<
    TChatMessageContainerViewProps,
    | 'case'
    | 'redirectToCaseFromChatRequest'
    | 'editing'
    | 'accountId'
    | 'iconType'
    | 'chatRoomType'
    | 'quoteMessage'
    | 'message'
    | 'readAll'
    | 'receiverId'
  >,
  'isMessageOutgoing'
> &
  TChatMessageWrapperBaseProps & {
    caseView?: string
  }

export type TChatMessageWrapperReturnProps = {
  messageRef: RefObject<HTMLDivElement>
  kebabRef: RefObject<HTMLDivElement>
  lastName: string
  firstName: string
  type: AccountTypeNames
  displayUserName: string
  userId: string
  shouldShowKebab: boolean
  contextMenuVisible: boolean
  caseView?: string
  longPressBind: LongPressResult<HTMLDivElement> | Record<string, never>
  onContextMenuVisibleChange: () => void
  onContextMenu: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}

export const MESSAGES = new Map<
  MessageTypeEnum,
  (props: {
    messageId: string
    chatId: string
    chatType?: ChatTypeEnum
    showUserName?: boolean
    caseView?: string
    footerRight?: ReactNode
    isMessageOutgoing?: boolean
    sender: TAccount | null
    quoteMessage: TChatQuoteMessage | null
  }) => JSX.Element
>()
  .set(MessageTypeEnum.CALL, ({ messageId, chatId, showUserName, footerRight }) => (
    <ChatMessageCallContainer
      id={messageId}
      chatId={chatId}
      showUserName={showUserName}
      footerRight={footerRight}
    />
  ))
  .set(MessageTypeEnum.AUDIO, ({ messageId, chatId, showUserName }) => (
    <ChatMessageAudioContainer id={messageId} chatId={chatId} showUserName={showUserName} />
  ))
  .set(MessageTypeEnum.TEXT, ({ messageId, chatId, showUserName }) => (
    <ChatMessageTextContainer id={messageId} chatId={chatId} showUserName={showUserName} />
  ))
  .set(MessageTypeEnum.DISCARDED_FILE_PERMISSION, ({ messageId, showUserName }) => (
    <ChatMessageDiscardedFilePermissionContainer id={messageId} showUserName={showUserName} />
  ))
  .set(MessageTypeEnum.FILE, ({ messageId, chatId, showUserName, caseView }) => (
    <ChatMessageFileContainer
      id={messageId}
      chatId={chatId}
      showUserName={showUserName}
      caseView={caseView}
    />
  ))
  .set(MessageTypeEnum.SYSTEM, ({ messageId, chatId }) => (
    <ChatMessageSystemContainer id={messageId} chatId={chatId} />
  ))
  .set(MessageTypeEnum.PINNED_MESSAGE, ({ quoteMessage, chatId, sender, chatType }) => (
    <ChatMessagePinnedMessage
      id={quoteMessage?.id ?? ''}
      quoteMessage={quoteMessage}
      chatId={chatId}
      sender={sender}
      chatType={chatType}
    />
  ))
  .set(MessageTypeEnum.MEETING, ({ messageId, isMessageOutgoing }) => (
    <ChatMessageMeetingContainer id={messageId} isMessageOutgoing={isMessageOutgoing} />
  ))

const DEFAULT_SIDER_FIXED_HEIGHT_PHONE = 50

const ChatMessageWrapperView = ({
  newMessageLineRef,
  sender,
  scrollListRef,
  showNewLineSeparator,
  createdAt,
  yourMessage,
  recipientRead,
  showDate,
  id,
  chatId,
  type: messageType,
  loading,
  sendStatus,
  hasReference,
  jumpHistoryEventPayload,
  chatViewType,
  isMessageOutgoing,
  getMessage,
  readMessages,
  onSetMessageRef,
  groupStart = false,
  caseView
}: TChatMessageWrapperProps) => {
  const messageRef = useRef<HTMLDivElement | null>(null)
  const kebabRef = useRef<HTMLDivElement | null>(null)

  const { isDesktop, isMobile } = useAdaptiveLayout()

  const { t } = useTranslation()

  const windowScrollUsed = chatViewType === EChatViewType.P2P && !isDesktop

  const rootMarginBottom = useMemo(() => {
    if (isMobile) {
      const siderFixedHeightPhoneParsed = parseInt(styleVariables?.siderFixedHeightPhone, 10)
      const siderFixedHeightPhone = Number.isNaN(siderFixedHeightPhoneParsed)
        ? DEFAULT_SIDER_FIXED_HEIGHT_PHONE
        : siderFixedHeightPhoneParsed

      return siderFixedHeightPhone
    }

    return 0
  }, [isMobile])

  const [contextMenuVisible, setContextMenuVisible] = useState<boolean>(false)
  const [animate, setAnimate] = useState<boolean>(false)

  const { isIntersecting } = useOnScreen(messageRef, {
    threshold: 0.9,
    root: windowScrollUsed ? null : scrollListRef.current,
    rootMargin: windowScrollUsed ? `0px 0px -${rootMarginBottom}px 0px` : undefined
  })

  const shouldShowKebab = useMemo(
    () =>
      messageType !== MessageTypeEnum.DELETED &&
      messageType !== MessageTypeEnum.DISCARDED_FILE_PERMISSION &&
      messageType !== MessageTypeEnum.SYSTEM &&
      messageType !== MessageTypeEnum.CHAT_REQUEST &&
      messageType !== MessageTypeEnum.PINNED_MESSAGE &&
      sendStatus !== ESendMessageStatus.SENDING,
    [messageType, sendStatus]
  )

  const onContextMenu = useCallback<LongPressCallback<HTMLDivElement>>(
    (event) => {
      if (!shouldShowKebab) {
        return
      }

      if (event?.cancelable) {
        event.preventDefault()
      }

      if (event && kebabRef.current && messageRef.current) {
        let x: number | null = null
        let y: number | null = null

        const touchEvent = event as ReactTouchEvent<HTMLDivElement>
        const mouseEvent = event as ReactMouseEvent<HTMLDivElement>

        if (touchEvent?.touches?.length) {
          const touch = touchEvent.touches[0]

          x = touch.clientX - messageRef.current.getBoundingClientRect().left
          y = touch.clientY - messageRef.current.getBoundingClientRect().top
        } else if (mouseEvent) {
          x = mouseEvent.clientX - messageRef.current.getBoundingClientRect().left
          y = mouseEvent.clientY - messageRef.current.getBoundingClientRect().top
        }

        if (x !== null && y !== null) {
          kebabRef.current.style.left = `${x}px`
          kebabRef.current.style.top = `${y}px`
        }
      }

      setContextMenuVisible(true)
    },
    [shouldShowKebab]
  )

  const longPressBind = useLongPress<HTMLDivElement>(!isDesktop ? onContextMenu : null, {
    captureEvent: true,
    detect: LongPressDetectEvents.BOTH
  })

  const onContextMenuVisibleChange = useCallback(
    (visible?: boolean) => {
      setContextMenuVisible(Boolean(visible))
    },
    [setContextMenuVisible]
  )

  useEffect(() => {
    if (isIntersecting && !yourMessage && !recipientRead) {
      readMessages({
        chatId,
        messageId: id
      })
    }
  }, [recipientRead, chatId, id, yourMessage, isIntersecting, readMessages])

  useEffect(() => {
    setAnimate(false)
  }, [jumpHistoryEventPayload?.eventTs, setAnimate])

  useEffect(() => {
    if (isIntersecting && !animate) {
      void messageRef.current?.offsetWidth

      setAnimate(true)
    }

    if (hasReference) {
      onSetMessageRef(messageRef)
    }
  }, [hasReference, isIntersecting, animate, onSetMessageRef])

  const shouldShowDivider = useMemo(() => {
    if (scrollListRef.current) {
      return showNewLineSeparator && !yourMessage && !scrollListRef.current.isScrollPositionBottom
    }

    return false
  }, [scrollListRef, showNewLineSeparator, yourMessage])

  if (messageType === MessageTypeEnum.DELETED) {
    return null
  }

  return (
    <div className={styles.root}>
      {showDate && !loading && <p className={styles.date}>{formatShortDate(createdAt)}</p>}
      {shouldShowDivider && (
        <NewDividerLine ref={newMessageLineRef}>
          {t('chat.message.newMessageDivider')}
        </NewDividerLine>
      )}

      <div
        className={cls({
          [styles.message]: true,
          [styles.messageOutgoing]: isMessageOutgoing,
          [styles.messageFocus]: hasReference && animate,
          [styles.groupStart]: groupStart
        })}
      >
        {getMessage({
          messageRef,
          kebabRef,
          lastName: sender?.lastName,
          firstName: sender?.firstName,
          type: sender?.type?.name,
          displayUserName: sender?.displayUserName,
          userId: sender?.id,
          shouldShowKebab,
          contextMenuVisible,
          longPressBind,
          caseView,
          onContextMenuVisibleChange,
          onContextMenu
        })}
      </div>
    </div>
  )
}

export const ChatMessageWrapper = memo(ChatMessageWrapperView)
