import { Row } from 'antd'
import cls from 'classnames'
import { useTranslation } from 'react-i18next'
import { TFunction } from 'i18next'

import { IFilesEntity } from 'interfaces'
import { getMapComponent } from 'utils'
import { EExtendableCardType } from 'store/extendableCard/extendableCard.types'
import { ButtonSpinner, FileNameTruncatedText, TFileViewerProps, Tooltip } from 'App/components'
import { useAdaptiveLayout } from 'App/hooks/useAdaptiveLayout'
import { PopoverCommentsWrapper } from 'App/containers/Showcase/Comments/PopoverCommentsWrapper'
import { ReactComponent as CrossIcon } from 'assets/icons/Cross.svg'
import { ReactComponent as DownloadIcon } from 'assets/icons/Download.svg'
import { ReactComponent as CommentIcon } from 'assets/icons/Comment.svg'

import { useDownload } from '../../hooks'
import { TFullscreenViewProps } from '../FullscreenView'

import styles from './HeaderToolbar.module.scss'

type THeaderToolbarProps = Pick<TFullscreenViewProps, 'type' | 'hide' | 'options' | 'slides'> & {
  currentSlide: TFileViewerProps
  currentSlideIndex: number
}

type TToolbarStartMapProps = Required<Pick<TFileViewerProps, 'extension' | 'id'>> &
  Pick<THeaderToolbarProps, 'options' | 'currentSlideIndex' | 'slides'> & {
    isMobile: boolean
    t: TFunction

    fileName?: string
  }
type TToolbarEndMapProps = ReturnType<typeof useDownload> &
  Pick<TFullscreenViewProps, 'options'> & {
    t: TFunction
  }

const TOOLBAR_START = new Map<EExtendableCardType, (props: TToolbarStartMapProps) => JSX.Element>()
  .set(EExtendableCardType.FILE_VIEWER, ({ isMobile, fileName, extension }) =>
    fileName ? (
      <FileNameTruncatedText
        className={styles.title}
        extension={extension}
        fileName={fileName}
        twoLine={isMobile}
      />
    ) : (
      <></>
    )
  )
  .set(EExtendableCardType.SHOWCASE_GALLERY, ({ options, id, currentSlideIndex, slides, t }) => {
    const { showcaseId, ownerId } = options?.showcaseOptions ?? {}

    return (
      <>
        {ownerId && showcaseId && (
          <PopoverCommentsWrapper
            files={slides as IFilesEntity[]}
            fileOrderId={currentSlideIndex}
            fileId={id}
            showcaseId={showcaseId}
            ownerId={ownerId}
          >
            <Tooltip title={t('common.card.fullscreenButtonTooltips.comments')}>
              <CommentIcon className={styles.icon} />
            </Tooltip>
          </PopoverCommentsWrapper>
        )}
      </>
    )
  })

const TOOLBAR_END = new Map<EExtendableCardType, (props: TToolbarEndMapProps) => JSX.Element>().set(
  EExtendableCardType.FILE_VIEWER,
  ({ processing, onDownload, t, options }) => {
    const download = options?.download ?? true

    return download ? (
      <Tooltip title={t('common.card.fullscreenButtonTooltips.download')}>
        {processing ? (
          <ButtonSpinner className={styles.icon} />
        ) : (
          <DownloadIcon className={styles.icon} onClick={processing ? undefined : onDownload} />
        )}
      </Tooltip>
    ) : (
      <></>
    )
  }
)

export const HeaderToolbar = ({
  type,
  currentSlide,
  currentSlideIndex,
  options,
  slides,
  hide
}: THeaderToolbarProps) => {
  const { isMobile } = useAdaptiveLayout()

  const { t } = useTranslation()

  const { id, source, originalEntityId, fileName, fileSrc, extension = '' } = currentSlide ?? {}

  const { onDownload, processing } = useDownload({ id, source, originalEntityId, fileSrc })

  return (
    <Row
      className={styles.header}
      align={isMobile ? 'bottom' : 'middle'}
      justify="space-between"
      wrap={false}
    >
      <Row
        className={cls(styles.row, type === EExtendableCardType.FILE_VIEWER && styles.toolbar)}
        align="middle"
        justify="space-between"
        wrap={false}
      >
        {getMapComponent(TOOLBAR_START, type, {
          fileName,
          extension,
          isMobile,
          id,
          options,
          currentSlideIndex,
          slides,
          t
        })}
      </Row>

      <Row className={styles.row} align="middle" justify="space-between" wrap={false}>
        {getMapComponent(TOOLBAR_END, type, { onDownload, processing, t, options })}

        <Tooltip title={t('common.card.fullscreenButtonTooltips.close')}>
          <CrossIcon className={styles.icon} onClick={hide} />
        </Tooltip>
      </Row>
    </Row>
  )
}
