import { ReactNode, useCallback, useMemo } from 'react'
import { AccountTypeNames, ChatTypeEnum } from '@medentee/enums'

import { API } from 'services/api'
import { QueryBuilder } from 'utils'
import { useAppDispatch } from 'store/store.utils'
import { EExtendableCardType, showExtendableCardAction } from 'store/extendableCard'

import { Image, TImagePops } from '../Image'

import { AvatarInitials } from './AvatarInitials'
import { AvatarWrapper, TAvatarWrapperProps } from './AvatarWrapper'
import styles from './Avatar.module.scss'

export type TAvatarProps = TAvatarWrapperProps &
  Pick<TImagePops, 'onLoad' | 'onError'> &
  PropsWithClassName<{
    userId: string
    firstName: string
    lastName: string
    displayUserName: string

    tooltip?: ReactNode | null
    hasTooltip?: boolean
    type?: AccountTypeNames
    chatType?: ChatTypeEnum
    isCommunity?: boolean
    isEvent?: boolean
    isEventProfile?: boolean
    isEventOrganizer?: boolean
    eventId?: string
    invitationToken?: string
    allowFullscreen?: boolean
  }>

const FULLSCREEN_AVATAR_ID = '1'

export const Avatar = ({
  // TODO:  Rework avatar. Update type to variant, userId to id, remove isCommunity & isEvent
  firstName,
  lastName,
  tooltip,
  border,
  userId,
  size,
  online,
  onlineBorderColor,
  onlineBorderCssRootPropertyValue,
  chatType,
  isCommunity,
  isEvent,
  isEventProfile,
  isEventOrganizer,
  eventId,
  invitationToken,
  className,
  allowFullscreen,
  onLoad,
  onError,
  onClick,
  type = AccountTypeNames.PROFESSIONAL,
  hasTooltip = true,
  displayUserName = ''
}: TAvatarProps) => {
  const dispatch = useAppDispatch()

  const getTooltip = () => {
    if (tooltip) {
      return tooltip
    }

    return hasTooltip && displayUserName
  }

  const src = useMemo(() => {
    if (isCommunity) {
      return new QueryBuilder(API.GET_COMMUNITY_AVATAR_URL(userId))
        .custom('invitationToken', invitationToken)
        .build()
    }

    if (isEvent) {
      return new QueryBuilder(API.EVENT_AVATAR(userId))
        .custom('invitationToken', invitationToken)
        .build()
    }

    if (isEventProfile && eventId) {
      return new QueryBuilder(API.EVENT_ATTENDEE_PROFILE_AVATAR(eventId, userId))
        .custom('invitationToken', invitationToken)
        .build()
    }

    if (isEventOrganizer && eventId) {
      return new QueryBuilder(API.EVENT_DIFFERENT_ORGANIZER_AVATAR(eventId))
        .custom('invitationToken', invitationToken)
        .build()
    }

    return chatType === ChatTypeEnum.GROUP
      ? API.GET_CHAT_GROUP_AVATAR_URL(userId)
      : API.GET_AVATAR_URL(userId)
  }, [
    isCommunity,
    isEvent,
    isEventProfile,
    eventId,
    isEventOrganizer,
    chatType,
    userId,
    invitationToken
  ])

  const handleAvatarClick = useCallback(() => {
    if (onClick) {
      return onClick()
    }

    if (!allowFullscreen) {
      return
    }

    dispatch(
      showExtendableCardAction({
        type: EExtendableCardType.FILE_VIEWER,
        initialSlideId: FULLSCREEN_AVATAR_ID,
        options: { download: false },
        slides: [
          {
            extension: 'jpeg',
            id: FULLSCREEN_AVATAR_ID,
            fileSrc: src,
            imageClasses: { root: styles.fullscreenAvatar, image: styles.fullscreenAvatarImage }
          }
        ]
      })
    )
  }, [allowFullscreen, dispatch, onClick, src])

  return (
    <AvatarWrapper
      size={size}
      online={online}
      onlineBorderColor={onlineBorderColor}
      onlineBorderCssRootPropertyValue={onlineBorderCssRootPropertyValue}
      border={border}
      color={chatType ?? type}
      tooltip={getTooltip()}
      onClick={handleAvatarClick}
      className={className}
    >
      <Image
        hidden={!src}
        src={src}
        alt="avatar"
        placeholder={
          <AvatarInitials
            displayUserName={displayUserName}
            firstName={firstName}
            lastName={lastName}
            type={type}
            chatType={chatType}
          />
        }
        onLoad={onLoad}
        onError={onError}
      />
    </AvatarWrapper>
  )
}
