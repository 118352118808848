import dateFnsGenerateConfig from 'rc-picker/lib/generate/dateFns'
import generatePicker, { RangePickerProps } from 'antd/es/date-picker/generatePicker'
import { useTranslation } from 'react-i18next'

import { ReactComponent as DashIcon } from 'assets/icons/Dash.svg'
import { HelperText } from 'App/components'

import { Error } from '../Error'

import styles from './TimePicker.module.scss'

export type TTimeRangePickerProps = Omit<RangePickerProps<Date>, 'picker'> & {
  error?: string
  invalid?: boolean
  topLabel?: string
  minuteStep?: number
  order?: boolean
}

const Picker = generatePicker<Date>(dateFnsGenerateConfig)

export const TimeRangePicker = (props: TTimeRangePickerProps) => {
  const { topLabel, error, invalid, minuteStep, ...rest } = props

  const { t } = useTranslation()

  return (
    <>
      {topLabel && <div className={styles.topLabel}>{topLabel}</div>}
      <Picker.RangePicker
        picker="time"
        size="large"
        format="HH:mm"
        minuteStep={minuteStep}
        inputReadOnly={true}
        placeholder={[
          t('common.field.timePicker.placeholder_start'),
          t('common.field.timePicker.placeholder_end')
        ]}
        {...rest}
        separator={<DashIcon className={styles.separator} />}
      />
      <HelperText>
        <Error error={error} invalid={invalid} />
      </HelperText>
    </>
  )
}
