import {
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react'
import { useTranslation } from 'react-i18next'

import { accountIdSelector, isAdminAccountSelector, useAppSelector } from 'store'
import { AccountsSelect, Alert, TAccountsSelectProps, TChannelDepartment } from 'App/components'
import { TAccountsSelectOptionData } from 'App/components/common/AccountsSelect/AccountsSelectOption/AccountsSelectOption'
import { EAccountsSelectType } from 'App/components/common/AccountsSelect'
import { useSelectAccounts } from 'App/hooks'

export type TUnityHubCreateChannelsMembersStepProps = Pick<
  TAccountsSelectProps,
  | 'total'
  | 'page'
  | 'onPageChange'
  | 'search'
  | 'limit'
  | 'onSearch'
  | 'items'
  | 'emptyListText'
  | 'loading'
  | 'error'
  | 'allowGroupSelect'
> & {
  variant: 'members' | 'manager'
  channelMembers: TAccountsSelectOptionData[]

  showAlert?: boolean
  alert?: ReactNode
  CustomSearchComponent?: ReactNode
  submitLoading?: boolean
  maxSelected?: number
  channelManagerId?: string
  channelDepartments?: TChannelDepartment[]
  departmentItems?: TAccountsSelectProps['groups']
  departmentsLoading?: TAccountsSelectProps['groupsLoading']
  departmentsPlaceholder?: TAccountsSelectProps['groupsPlaceholder']
  departmentsSearchPlaceholder?: TAccountsSelectProps['groupsSearchPlaceholder']
  onBack?: () => void
  onSubmit?: (accounts: TAccountsSelectOptionData[], type: EAccountsSelectType) => void
  setChannelMembers?: (accounts: TAccountsSelectOptionData[]) => void
  setChannelManager?: (accounts?: TAccountsSelectOptionData[]) => void
  setChannelDepartments?: (departments: TChannelDepartment[]) => void
  onSelectTypeChange?: Dispatch<SetStateAction<EAccountsSelectType>>
}

export const UnityHubCreateChannelsMembersStep = ({
  channelMembers,
  showAlert,
  alert,
  variant,
  CustomSearchComponent,
  channelManagerId,
  maxSelected,
  submitLoading,
  error,
  total,
  search,
  items,
  departmentItems,
  limit,
  page,
  emptyListText,
  loading,
  channelDepartments,
  departmentsLoading,
  departmentsPlaceholder,
  departmentsSearchPlaceholder,
  allowGroupSelect,
  setChannelMembers,
  setChannelManager,
  setChannelDepartments,
  onSelectTypeChange,
  onSearch,
  onPageChange,
  onBack,
  onSubmit
}: TUnityHubCreateChannelsMembersStepProps) => {
  const isBusiness = useAppSelector(isAdminAccountSelector)
  const accountId = useAppSelector(accountIdSelector)

  const { t } = useTranslation()

  const [selectType, setSelectType] = useState<EAccountsSelectType>(EAccountsSelectType.BY_USER)

  const isManagerVariant = variant === 'manager'

  const placeholderContent = useMemo(() => {
    if (isManagerVariant) {
      return t('modal.createChannel.selectMembers.selectedListPlaceholder_manager')
    }

    if (selectType === EAccountsSelectType.BY_GROUP) {
      return t('modal.createChannel.selectMembers.selectedListPlaceholder_departments')
    }

    return t('modal.createChannel.selectMembers.selectedListPlaceholder_members')
  }, [isManagerVariant, selectType, t])

  const isItemDisabled = useCallback(
    (id: string) => id === channelManagerId && channelManagerId === accountId && !isBusiness,
    [accountId, channelManagerId, isBusiness]
  )

  const formattedItems = useMemo(
    () => items.map((item) => ({ ...item, disabled: isItemDisabled(item.id) })),
    [isItemDisabled, items]
  )

  const handleOnSubmit = useCallback(
    (value: TAccountsSelectOptionData[]) => {
      if (isManagerVariant && setChannelManager) {
        setChannelManager(value)
      } else if (selectType === EAccountsSelectType.BY_GROUP) {
        const departments = value.map<TChannelDepartment>(({ id, displayUserName }) => ({
          id,
          name: displayUserName,
          isChatsParticipant: true,
          isVoiceRoomsParticipant: true
        }))

        setChannelMembers && setChannelMembers([])
        setChannelDepartments && setChannelDepartments(departments)
      } else {
        setChannelDepartments && setChannelDepartments([])
        setChannelMembers && setChannelMembers(value)
      }

      if (
        isBusiness &&
        !!setChannelManager &&
        !!channelManagerId &&
        (!value.length || !value.some(({ id }) => id === channelManagerId)) &&
        !isManagerVariant
      ) {
        setChannelManager(undefined)
      }

      if (onBack) {
        onBack()
      }

      if (onSubmit) {
        onSubmit(value, selectType)
      }
    },
    [
      isManagerVariant,
      setChannelManager,
      setChannelDepartments,
      selectType,
      setChannelMembers,
      isBusiness,
      channelManagerId,
      onBack,
      onSubmit
    ]
  )

  const {
    state: { selectedAccounts },
    handleSubmit,
    onSelect,
    onUnselect,
    onUnselectAll,
    onSelectGroup
  } = useSelectAccounts({
    accounts: [],
    groups: departmentItems,
    onSubmit: handleOnSubmit
  })

  const handleGroupChange = useCallback(
    (selectedIds: string[]) => {
      const departments =
        departmentItems
          ?.filter(({ value }) => selectedIds.includes(value))
          ?.map<TAccountsSelectOptionData>(({ label, value }) => ({
            id: value,
            displayUserName: label
          })) ?? []

      onSelectGroup(departments)
    },
    [departmentItems, onSelectGroup]
  )

  const handleTypeChange = useCallback(
    (nextType: SetStateAction<EAccountsSelectType>) => {
      if (selectType && nextType !== selectType) {
        onUnselectAll()
      }

      setSelectType(nextType)
    },
    [onUnselectAll, selectType]
  )

  useEffect(() => {
    if (!loading && selectType === EAccountsSelectType.BY_USER) {
      onSelectGroup(
        channelMembers.map((item) => ({
          ...item,
          disabled: isItemDisabled(item.id)
        }))
      )
    }
  }, [channelMembers, isItemDisabled, loading, onSelectGroup, selectType])

  useEffect(() => {
    if (!departmentsLoading && channelDepartments && selectType === EAccountsSelectType.BY_GROUP) {
      onSelectGroup(
        channelDepartments.map(({ id, name }) => ({
          id,
          displayUserName: name
        }))
      )
    }
  }, [channelDepartments, departmentsLoading, onSelectGroup, selectType])

  useEffect(() => {
    setSelectType((prev) => {
      if (
        !departmentsLoading &&
        !!channelDepartments?.length &&
        prev !== EAccountsSelectType.BY_GROUP
      ) {
        return EAccountsSelectType.BY_GROUP
      }

      return EAccountsSelectType.BY_USER
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channelDepartments?.length])

  useEffect(() => {
    onSelectTypeChange && onSelectTypeChange(selectType)
  }, [onSelectTypeChange, onUnselectAll, selectType])

  return (
    <AccountsSelect
      alert={
        showAlert ? (
          <Alert variant="info" size="sm">
            {alert}
          </Alert>
        ) : null
      }
      CustomSearchComponent={CustomSearchComponent}
      items={formattedItems}
      total={total}
      limit={limit}
      page={page}
      search={search}
      selectedItems={selectedAccounts}
      groups={departmentItems}
      groupsLoading={departmentsLoading}
      groupsSearchPlaceholder={departmentsSearchPlaceholder}
      groupsPlaceholder={departmentsPlaceholder}
      emptyListText={emptyListText}
      error={error}
      loading={loading}
      submitLoading={submitLoading}
      maxSelected={maxSelected}
      submitLabel={t('modal.createChannel.selectMembers.submitButton')}
      submitDisabled={loading}
      emptyContentText={isBusiness ? placeholderContent : undefined}
      autoOnline={true}
      allowGroupSelect={allowGroupSelect}
      selectType={selectType}
      onPageChange={onPageChange}
      onSearch={onSearch}
      onClickBack={onBack}
      onSubmit={handleSubmit}
      onSelect={onSelect}
      onUnselect={onUnselect}
      onUnselectAll={onUnselectAll}
      onGroupsChange={handleGroupChange}
      onTypeChange={handleTypeChange}
    />
  )
}
